"use strict";

import React from 'react';
import PropTypes from 'prop-types';
import Notification from '../types/Notification';
import AuthActions from '../actions/AuthActions';
import AppStore from '../stores/AppStore';
import * as ErrorParser from './ErrorParser';
import AuthStore from '../stores/AuthStore';
import Button from '../components/ui/Button.react';

/**
 * Standard notifications
 *
 * @type {Object}
 */
export default {
    // sign up user
    SIGN_UP_USER_SUCCESS: () => new Notification({
        type: 'success',
        content: AppStore.translate('notification.account.success.signed_up'),
        hideAfter: 10000
    }),
    SIGN_UP_USER_ERROR: error => new Notification({
        type: 'error',
        content: ErrorParser.parseErrorMessage(error.message) || AppStore.translate('notification.general.error.communication_error'),
        hideAfter: 10000
    }),

    // sign in user
    SIGN_IN_USER_SUCCESS: () => new Notification({
        type: 'success',
        content: AppStore.translate('notification.account.success.signed_in')
    }),
    SIGN_IN_USER_ERROR: error => {
        let content = ErrorParser.parseErrorMessage(error.message);

        return new Notification({
            type: 'error',
            content: content,
            hideAfter: 30000
        });
    },

    // resend activation email
    RESEND_ACTIVATION_EMAIL_SUCCESS: () => new Notification({
        type: 'success',
        content: AppStore.translate('notification.account.success.activation_email_resent')
    }),
    RESEND_ACTIVATION_EMAIL_ERROR: error => new Notification({
        type: 'error',
        content: error.message || AppStore.translate('notification.general.error.communication_error'),
        hideAfter: 30000
    }),

    // activate user
    ACTIVATE_USER_SUCCESS: () => new Notification({
        type: 'success',
        content: AppStore.translate('notification.account.success.account_activated')
    }),
    ACTIVATE_USER_ERROR: error => new Notification({
        type: 'error',
        content: error.message || AppStore.translate('notification.general.error.communication_error')
    }),

    // change user password
    CHANGE_USER_PASSWORD_SUCCESS: () => new Notification({
        type: 'success',
        content: AppStore.translate('notification.account.success.password_changed')
    }),
    CHANGE_USER_PASSWORD_ERROR: error => new Notification({
        type: 'error',
        content: ErrorParser.parseErrorMessage(error.message) || AppStore.translate('notification.general.error.communication_error')
    }),

    // update user profile
    UPDATE_USER_PROFILE_SUCCESS: () => new Notification({
        type: 'success',
        content: AppStore.translate('notification.account.success.profile_updated')
    }),
    UPDATE_USER_PROFILE_ERROR: error => new Notification({
        type: 'error',
        content: error.message || AppStore.translate('notification.general.error.communication_error')
    }),

    // update user preferences
    UPDATE_USER_PREFERENCES_SUCCESS: () => new Notification({
        type: 'success',
        content: AppStore.translate('notification.account.success.preferences_updated')
    }),
    UPDATE_USER_PREFERENCES_ERROR: error => new Notification({
        type: 'error',
        content: error.message || AppStore.translate('notification.general.error.communication_error')
    }),

    // sign out user
    SIGN_OUT_USER: () => new Notification({
        content: AppStore.translate('notification.account.success.signed_out')
    }),
    // Timeout user
    REQUEST_SESSION_TIMEOUT: () => new Notification({
        content: AppStore.translate('notification.account.success.timeout_user')
    }),
    // request password reset
    SEND_RESET_USER_PASSWORD_EMAIL_SUCCESS: () => new Notification({
        type: 'success',
        content: AppStore.translate('notification.account.success.password_reset_requested')
    }),
    SEND_RESET_USER_PASSWORD_EMAIL_ERROR: error => new Notification({
        type: 'error',
        content: ErrorParser.parseErrorMessage(error.message) || AppStore.translate('notification.general.error.communication_error')
    }),

    // add payment method to user account
    // update payment method to user account
    SET_PAYMENT_METHOD_SUCCESS: () => new Notification({
        type: 'success',
        content: AppStore.translate('notification.account.success.payment_method_set')
    }),
    SET_PAYMENT_METHOD_ERROR: error => new Notification({
        type: 'error',
        content: ErrorParser.parseErrorMessage(error.message) || AppStore.translate('notification.general.error.communication_error')
    }),

    // add/remove media item from watchlist
    ADD_REMOVE_MEDIA_ITEM_FROM_WATCHLIST_ERROR: error => new Notification({
        type: 'error',
        content: ErrorParser.parseErrorMessage(error.message) || AppStore.translate('notification.general.error.communication_error')
    }),

    // checkout media item offer
    CHECKOUT_MEDIA_ITEM_OFFER_ERROR: error => new Notification({
        type: 'error',
        content: ErrorParser.parseErrorMessage(error.message) || AppStore.translate('notification.general.error.communication_error')
    }),

    // remove registered device error
    REMOVE_REGISTERED_DEVICE_ERROR: error => new Notification({
        type: 'error',
        content: ErrorParser.parseErrorMessage(error.message) || AppStore.translate('notification.general.error.communication_error')
    }),

    // remove registered device error
    RENAME_REGISTERED_DEVICE_ERROR: error => new Notification({
        type: 'error',
        content: ErrorParser.parseErrorMessage(error.message) || AppStore.translate('notification.general.error.communication_error')
    }),

    // charge basket error
    CHARGE_BASKET_ERROR: error => new Notification({
        type: 'error',
        hideAfter: 6000,
        content: ErrorParser.parseErrorMessage(error.message)
    }),


};

/**
 * ActivationEmailResendButton component
 */
class ActivationEmailResendButton extends React.Component {
    /**
     * React: propTypes
     */
    static propTypes = {
        email: PropTypes.string
    };

    /**
     * React: state
     */
    state = {
        updatePending: false
    };

    /**
     * React: componentDidMount
     */
    componentDidMount() {
        AuthStore.addUpdateListener(this._setButtonState);
    }

    /**
     * React: componentWillUnmount
     */
    componentWillUnmount() {
        AuthStore.removeListener(AuthStore.STORE_UPDATED, this._setButtonState);
        this.setState({ updatePending: false });
    }

    /**
     * React: render
     */
    render() {
        return (
            <Button shape="square-chromeless"
                onClick={AuthActions.resendActivationEmail.bind(null, { email: this.props.email })}
                updatePending={this.state.updatePending}
                disabled={this.state.updatePending}>
                <i className="ui-icon icon-mail" />{AppStore.translate('button.resend_activation_email')}
            </Button>
        );
    }

    /**
     * Set button status
     */
    _setButtonState = () => {
        this.setState({ updatePending: AuthStore.updatePending });
    };
}
