"use strict";

import Immutable from 'immutable';
import BasketItem from '../types/BasketItem';
import Voucher from '../types/Voucher';
import ActionTypes from '../actions/ActionTypes';
import BaseStore from './BaseStore';
import AuthStore from '../stores/AuthStore';
import MediaStore from '../stores/MediaStore';
import EntitlementStore from '../stores/EntitlementStore';
import BundleShoppingCart from '../types/BundleShoppingCart';
import {waitFor} from '../dispatchers/AppDispatcher';

/**
 * Flux: PurchaseStore
 */
class PurchaseStore extends BaseStore {
    /**
     * Basket properties
     *
     * @type {Immutable.Map<string, BasketItem>} _basket
     * @type {string} _basketMediaBucketKey
     * @type {boolean} _updatePending
     * @type {boolean} _purchasePending
     * @type {boolean} _voucherPending
     * @type {?Voucher} _voucher
     * @type {?number} _basketAmountDue
     * @type {?string} _basketCurrencyCode
     * @type {Immutable.Map<string, BundleShoppingCart>} _bundleShoppingCartStorage
     * @private
     */
    _basket = Immutable.Map();
    _basketMediaBucketKey = '';
    _updatePending = false;
    _purchasePending = false;
    _voucherPending = false;
    _voucher = null;
    _basketAmountDue = null;
    _basketCurrencyCode = null;
    _basketBundleCollectionId = null;
    _bundleShoppingCartStorage = Immutable.Map();

    /**
     * Constructor
     */
    constructor() {
        super();
        this.subscribe(() => this._registerToActions.bind(this));
    }

    /**
     * Flux: register store to actions
     *
     * @param action
     * @private
     */
    _registerToActions(action) {
        switch (action.type) {
            // bundle shopping cart actions
            case ActionTypes.ADD_MEDIA_ITEM_OFFER_TO_BUNDLE_SHOPPING_CART:
                this._addBundleShoppingCartItem(action.collectionId, action.mediaItemId, action.offerId);
                this._error = null;
                this.emitUpdate();
                break;

            case ActionTypes.REMOVE_MEDIA_ITEM_OFFER_FROM_BUNDLE_SHOPPING_CART:
                this._removeBundleShoppingCartItem(action.collectionId, action.mediaItemId);
                this._error = null;
                this.emitUpdate();
                break;

            case ActionTypes.REQUEST_GET_BUNDLE_SHOPPING_CART_VALUE:
                if (action.isShoppingCartFull) {
                    let bundleShoppingCart = this._bundleShoppingCartStorage.get(action.collectionId);
                    bundleShoppingCart = bundleShoppingCart.setUpdatePending(true);
                    bundleShoppingCart = bundleShoppingCart.setCartValue(0, '');
                    this._bundleShoppingCartStorage = this._bundleShoppingCartStorage.set(action.collectionId, bundleShoppingCart);
                }
                this._error = null;
                this.emitUpdate();
                break;

            case ActionTypes.REQUEST_GET_BUNDLE_SHOPPING_CART_VALUE_SUCCESS:
                if (action.isShoppingCartFull) {
                    let bundleShoppingCart = this._bundleShoppingCartStorage.get(action.collectionId);
                    bundleShoppingCart = bundleShoppingCart.setUpdatePending(false);
                    bundleShoppingCart = bundleShoppingCart.setCartValue(action.amountDue, action.currencyCode);
                    this._bundleShoppingCartStorage = this._bundleShoppingCartStorage.set(action.collectionId, bundleShoppingCart);
                }
                this._error = null;
                this.emitUpdate();
                break;

            case ActionTypes.REQUEST_GET_BUNDLE_SHOPPING_CART_VALUE_ERROR:
                if (action.isShoppingCartFull) {
                    let bundleShoppingCart = this._bundleShoppingCartStorage.get(action.collectionId);
                    bundleShoppingCart = bundleShoppingCart.setUpdatePending(false);
                    bundleShoppingCart = bundleShoppingCart.setCartValue(null, null);
                    this._bundleShoppingCartStorage = this._bundleShoppingCartStorage.set(action.collectionId, bundleShoppingCart);
                }
                this._error = action.error;
                this.emitUpdate();
                break;

            // checkout media item offer
            case ActionTypes.REQUEST_FAST_CHECKOUT_MEDIA_ITEM_OFFER:
                MediaStore.destroyMediaBucket(this._basketMediaBucketKey);
                this._basketMediaBucketKey = action.uuid;
                this._basket = Immutable.Map();
                this._basket = this._basket.set(action.offerId, new BasketItem({
                    offerId: action.offerId,
                    mediaItemId: action.mediaItemId,
                    isInBasket: false,
                    updatePending: true
                }));
                this._voucher = null;
                this._basketAmountDue = null;
                this._basketCurrencyCode = null;
                this._basketBundleCollectionId = null;
                this._error = null;
                this.emitUpdate();
                break;

            case ActionTypes.REQUEST_FAST_CHECKOUT_MEDIA_ITEM_OFFER_SUCCESS:
                waitFor([AuthStore.dispatchToken]);
                if (action.basketItemDTO) {
                    const basketItem = new BasketItem({}).fromDTO(action.basketItemDTO);
                    this._basket = this._basket.set(action.offerId, basketItem);
                    this._basketAmountDue = basketItem.offer.price;
                    this._basketCurrencyCode = basketItem.offer.currency;
                }
                this._error = null;
                this.emitUpdate();
                break;

            case ActionTypes.REQUEST_FAST_CHECKOUT_MEDIA_ITEM_OFFER_ERROR:
                this._basket = this._basket.delete(action.offerId);
                this._error = action.error;
                this.emitUpdate();
                break;

            // checkout media bundle offer
            case ActionTypes.REQUEST_FAST_CHECKOUT_BUNDLE_OFFER:
                MediaStore.destroyMediaBucket(this._basketMediaBucketKey);
                this._basketMediaBucketKey = action.uuid;
                this._basket = Immutable.Map();
                this._updatePending = true;
                this._voucher = null;
                this._basketAmountDue = null;
                this._basketCurrencyCode = null;
                this._basketBundleCollectionId = null;
                this._error = null;
                this.emitUpdate();
                break;

            case ActionTypes.REQUEST_FAST_CHECKOUT_BUNDLE_OFFER_SUCCESS:
                waitFor([AuthStore.dispatchToken]);
                this._setBasketItems(action.basketItemDTOCollection);
                this._basketAmountDue = action.amountDue;
                this._basketCurrencyCode = action.currencyCode;
                this._basketBundleCollectionId = action.collectionId;
                this._updatePending = false;
                this._error = null;
                this.emitUpdate();
                break;

            case ActionTypes.REQUEST_FAST_CHECKOUT_BUNDLE_OFFER_ERROR:
                this._updatePending = false;
                this._error = action.error;
                this.emitUpdate();
                break;

            // cancel checkout
            case ActionTypes.CANCEL_CHECKOUT:
                this._basketMediaBucketKey = '';
                this._updatePending = false;
                this._purchasePending = false;
                this._voucherPending = false;
                this._voucher = null;
                this._basketAmountDue = null;
                this._basketCurrencyCode = null;
                this.emitUpdate();
                break;

            // charge basket
            case ActionTypes.REQUEST_CHARGE_BASKET:
                this._updatePending = true;
                this._purchasePending = true;
                this._error = null;
                this.emitUpdate();
                break;

            case ActionTypes.REQUEST_CHARGE_BASKET_SUCCESS:
                waitFor([EntitlementStore.dispatchToken]);
                this._bundleShoppingCartStorage = this._bundleShoppingCartStorage.set(this._basketBundleCollectionId, new BundleShoppingCart({collectionId: action.collectionId}));
                this._updatePending = false;
                this._purchasePending = false;
                MediaStore.registerMediaStateChange();
                this._error = null;
                this.emitUpdate();
                break;

            case ActionTypes.REQUEST_CHARGE_BASKET_ERROR:
                this._updatePending = false;
                this._purchasePending = false;
                this._error = action.error;
                this.emitUpdate();
                break;

            // verify voucher
            case ActionTypes.REQUEST_VERIFY_VOUCHER:
                this._updatePending = true;
                this._voucherPending = true;
                this._error = null;
                this.emitUpdate();
                break;

            case ActionTypes.REQUEST_VERIFY_VOUCHER_SUCCESS:
                this._updatePending = false;
                this._voucherPending = false;
                this._voucher = new Voucher({}).fromDTO(action.voucherDTO);
                this._error = null;
                this.emitUpdate();
                break;

            case ActionTypes.REQUEST_VERIFY_VOUCHER_ERROR:
                this._updatePending = false;
                this._voucherPending = false;
                this._error = action.error;
                this.emitUpdate();
                break;

            // create purchase order vod item
            case ActionTypes.REQUEST_CREATE_PURCHASE_ORDER_VOD_ITEM:
                this._error = null;
                break;

            case ActionTypes.REQUEST_CREATE_PURCHASE_ORDER_VOD_ITEM_SUCCESS:
                waitFor([EntitlementStore.dispatchToken]);
                MediaStore.registerMediaStateChange();
                this._error = null;
                this.emitUpdate();
                break;

            case ActionTypes.REQUEST_CREATE_PURCHASE_ORDER_VOD_ITEM_ERROR:
                this._error = action.error;
                this.emitUpdate();
                break;

            // waited for by AuthStore: sign out user
            case ActionTypes.SIGN_OUT_USER:
                case ActionTypes.REQUEST_SESSION_TIMEOUT:
                // clear important maps
                this._basket = Immutable.Map();
                this._bundleShoppingCartStorage = Immutable.Map();
                break;

            default:
                break;
        }
    }

    /**
     * Set _basket items
     *
     * @param {Array<BasketItemDTO>} basketItemDTOCollection
     * @private
     */
    _setBasketItems(basketItemDTOCollection) {
        /** @type {BasketItemDTO} basketItemDTO */
        basketItemDTOCollection.forEach((basketItemDTO) => {
            const basketItem = new BasketItem({}).fromDTO(basketItemDTO);
            if (basketItem.offerId) {
                this._basket = this._basket.set(basketItem.offerId, basketItem);
            }
        });
    }

    /**
     * Adds bundle shopping cart item
     *
     * @param {number} collectionId
     * @param {string} mediaItemId
     * @param {number} offerId
     * @private
     */
    _addBundleShoppingCartItem(collectionId, mediaItemId, offerId) {
        let bundleShoppingCart = this._bundleShoppingCartStorage.get(collectionId);
        bundleShoppingCart = bundleShoppingCart.addCartItem(mediaItemId, offerId);
        this._bundleShoppingCartStorage = this._bundleShoppingCartStorage.set(collectionId, bundleShoppingCart);
    }

    /**
     * Removes bundle shopping cart item
     *
     * @param {number} collectionId
     * @param {string} mediaItemId
     * @private
     */
    _removeBundleShoppingCartItem(collectionId, mediaItemId) {
        let bundleShoppingCart = this._bundleShoppingCartStorage.get(collectionId);
        bundleShoppingCart = bundleShoppingCart.removeCartItem(mediaItemId);
        this._bundleShoppingCartStorage = this._bundleShoppingCartStorage.set(collectionId, bundleShoppingCart);
    }

    /**
     * Get budle shopping cart from storage
     *
     * returns {?MediaBucket}
     */
    getBundleShoppingCart(collectionId) {
        if (!this._bundleShoppingCartStorage.get(collectionId)) {
            this._bundleShoppingCartStorage = this._bundleShoppingCartStorage.set(collectionId, new BundleShoppingCart({collectionId}));
        }

        return this._bundleShoppingCartStorage.get(collectionId);
    }

    /**
     * Get _basket
     *
     * @returns {Immutable.Map<number, BasketItem>}
     */
    get basket() {
        return this._basket;
    }

    /**
     * Get _basketMediaBucketKey
     *
     * @returns {string}
     */
    get basketMediaBucketKey() {
        return this._basketMediaBucketKey;
    }

    /**
     * Get _updatePending
     *
     * @returns {boolean}
     */
    get updatePending() {
        return this._updatePending;
    }

    /**
     * Get _purchasePending
     *
     * @returns {boolean}
     */
    get purchasePending() {
        return this._purchasePending;
    }

    /**
     * Get _voucherPending
     *
     * @returns {boolean}
     */
    get voucherPending() {
        return this._voucherPending;
    }

    /**
     * Get _voucher
     *
     * @returns {Voucher}
     */
    get voucher() {
        return this._voucher;
    }

    /**
     * Get _basketAmountDue
     *
     * @returns {string}
     */
    get basketAmountDue() {
        return this._basketAmountDue;
    }

    /**
     * Get _basketCurrencyCode
     *
     * @returns {number}
     */
    get basketCurrencyCode() {
        return this._basketCurrencyCode;
    }

    /**
     * Get _basketBundleCollectionId
     *
     * @returns {number}
     */
    get basketBundleCollectionId() {
        return this._basketBundleCollectionId;
    }

    /**
     * Get _error
     *
     * @returns {null|*}
     */
    get error() {
        return this._error;
    }
}

export default new PurchaseStore();
