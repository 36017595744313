"use strict";

import ua from 'universal-analytics';
import crypto from 'crypto';
import Config from '../lib/Config';
import {waitFor} from '../dispatchers/AppDispatcher';
import BaseStore from './BaseStore';
import ActionTypes from '../actions/ActionTypes';
import VubiquityAnalytics, {VubiquityCustomEventType} from '../lib/VubiquityAnalytics';
import AppStore from './AppStore';
import AuthStore from './AuthStore';
import MediaStore from './MediaStore';
import PurchaseStore from './PurchaseStore';
import EntitlementStore from './EntitlementStore';
import WatchlistStore from './WatchlistStore';
import {UserIdentityStatusType} from '../types/UserIdentity';
import ErrorResponseStripe from '../lib/ErrorResponseStripe';

/**
 * Flux: AnalyticsStore
 */
class AnalyticsStore extends BaseStore {
    /**
     * Constructor
     */
    constructor() {
        super();
        this.subscribe(() => this._registerToActions.bind(this));
    }

    /**
     * Universal analytics Visitor ga instance
     * initialize for simple visitor
     *
     * @type {?Object}
     * @private
     */
    _ga = null;

    /**
     * Map of items that should be tracked in GA
     *
     * @type {?Object}
     * @private
     */
    _basketItemsToTrack = {};

    /**
     * Map of items that should be tracked in GA
     *
     * @type {?Object}
     * @private
     */
    _lastPurchaseType = '';

    /**
     * Flux: register store to actions
     *
     * @param action
     * @private
     */
    _registerToActions(action) {
        this._error = null;

        switch (action.type) {
            // Track page view
            case ActionTypes.TRACK_PAGE_VIEW:
                this._pageView(action.analyticsPayload.pathname);
                this.emitUpdate();
                break;

            // Track event
            case ActionTypes.TRACK_EVENT:
                if (action.analyticsPayload) {
                    const analyticsPayload = action.analyticsPayload;
                    const {category, action: eventAction, label, value, payload} = analyticsPayload;
                    this._event(category, eventAction, label, value, payload);
                }
                this.emitUpdate();
                break;

            // Track custom event
            case ActionTypes.TRACK_CUSTOM_EVENT:
                if (action.analyticsPayload) {
                    const analyticsPayload = action.analyticsPayload;
                    const {customEventType, params} = analyticsPayload;
                    this._customEvent(customEventType, params);
                }
                this.emitUpdate();
                break;

            // on AppStore: initialize session
            // on AuthStore: sign out user
            case ActionTypes.STORE_DEVICE_TYPE:
            case ActionTypes.SIGN_OUT_USER:
                case ActionTypes.REQUEST_SESSION_TIMEOUT:
                if (action.type === ActionTypes.STORE_DEVICE_TYPE) waitFor([AppStore.dispatchToken]);
                if (action.type === ActionTypes.SIGN_OUT_USER || action.type === ActionTypes.REQUEST_SESSION_TIMEOUT ) {
                    waitFor([AuthStore.dispatchToken]);
                    this._event('Sign Out', 'Sign Out', 'Signed Out');
                }
                this._initializeGASession(AuthStore.identity && AuthStore.identity.username);
                this.emitUpdate();
                break;

            // on AuthStore: sign up user
            case ActionTypes.REQUEST_SIGN_UP_USER_SUCCESS:
                waitFor([AuthStore.dispatchToken]);
                this._event('Registration', 'Sign Up');
                this.emitUpdate();
                break;

            // on AuthStore: sign up user (error)
            // on AuthStore: user sign up with auto Sign In (error)
            case ActionTypes.REQUEST_SIGN_UP_USER_ERROR:
            case ActionTypes.REQUEST_SIGN_UP_USER_THEN_AUTO_SIGN_IN_ERROR:
                waitFor([AuthStore.dispatchToken]);
                this._event('Registration', 'Sign Up', 'Email Exists');
                this.emitUpdate();
                break;

            // on AuthStore: Sign In user
            case ActionTypes.REQUEST_SIGN_IN_USER_SUCCESS:
                waitFor([AuthStore.dispatchToken]);
                if (action.userIdentityDTO && action.userIdentityDTO.AffiliateStatus) {
                    let userIdentityAffiliateStatus = UserIdentityStatusType.getValue(action.userIdentityDTO.AffiliateStatus, 'statusCodeAffiliate');
                    if (userIdentityAffiliateStatus === UserIdentityStatusType.STATUS_ACTIVE
                        || userIdentityAffiliateStatus === UserIdentityStatusType.STATUS_UNKNOWN) {
                        this._initializeGASession(AuthStore.identity.username);
                    }
                }
                this._event('Sign In', 'Sign In', 'Sign In Successful');
                this.emitUpdate();
                break;

            // on AuthStore: user sign up with auto Sign In (success)
            case ActionTypes.REQUEST_SIGN_UP_USER_THEN_AUTO_SIGN_IN_SUCCESS:
                waitFor([AuthStore.dispatchToken]);
                if (action.userIdentityDTO.AffiliateStatus) {
                    let userIdentityAffiliateStatus = UserIdentityStatusType.getValue(action.userIdentityDTO.AffiliateStatus, 'statusCodeAffiliate');
                    if (userIdentityAffiliateStatus === UserIdentityStatusType.STATUS_ACTIVE
                        || userIdentityAffiliateStatus === UserIdentityStatusType.STATUS_UNKNOWN) {
                        this._event('Registration', 'Sign Up');
                        this._initializeGASession(AuthStore.identity.username);
                    }
                }
                this.emitUpdate();
                break;

            // on AuthStore: user sign up with auto Sign In (error)
            case ActionTypes.REQUEST_SIGN_IN_USER_ERROR:
                waitFor([AuthStore.dispatchToken]);
                this._event('Sign In', 'Sign In', 'Error Sign In');
                this.emitUpdate();
                break;

            // update or register user payment method
            case ActionTypes.REQUEST_UPDATE_PAYMENT_METHOD_ERROR:
            case ActionTypes.REQUEST_ADD_PAYMENT_METHOD_ERROR:
                waitFor([AuthStore.dispatchToken]);
                switch (action.error.message) {
                    case ErrorResponseStripe.CREDIT_CARD_ERROR:
                        this._event('My Account', 'Add Payment', 'CC Error / Stripe Validation(Dynamic)');
                        break;
                    case ErrorResponseStripe.EXPIRATION_DATE_ERROR:
                        this._event('My Account', 'Add Payment', 'Incorrect Expiry Date');
                        break;
                    case ErrorResponseStripe.SECURITY_CODE_ERROR:
                        this._event('My Account', 'Add Payment', 'Incorrect CVC');
                        break;
                    case ErrorResponseStripe.CARD_DECLINED:
                        this._event('My Account', 'Add Payment', 'CardDeclined');
                        break;
                    case ErrorResponseStripe.CARD_INCOMPLETE:
                        this._event('My Account', 'Add Payment', 'Incomplete Card Number');
                        break;
                    case ErrorResponseStripe.PROCESSING_ERROR:
                        this._event('My Account', 'Add Payment', 'ProcessingError');
                        break;
                    default:
                        break;
                }
                this.emitUpdate();
                break;

            // on MediaStore: search media
            case ActionTypes.REQUEST_SEARCH_MEDIA:
                waitFor([MediaStore.dispatchToken]);
                if (action.query !== 'all') this._event('discovery', 'Search', action.query);
                this.emitUpdate();
                break;

            // on MediaStore: search media (success)
            case ActionTypes.REQUEST_SEARCH_MEDIA_SUCCESS:
                waitFor([MediaStore.dispatchToken]);
                this._customEvent(VubiquityCustomEventType.LOG_SEARCH, {
                    searchQuery: MediaStore.searchQueryString,
                    searchType: 'generic',
                    resultTotalCount: MediaStore.searchResultTotalCount
                });
                if (MediaStore.searchResultTotalCount < 1) this._event('discovery', 'Search', 'No results');
                this.emitUpdate();
                break;

            // on fast checkout media item offer success
            case ActionTypes.REQUEST_FAST_CHECKOUT_MEDIA_ITEM_OFFER_SUCCESS:
                waitFor([MediaStore.dispatchToken]);
                this._event(
                    'Purchase',
                    typeof action.basketItemDTO.MediaItemDTO.Collections !== 'undefined'
                        && action.basketItemDTO.MediaItemDTO.Collections[0] === 'Box Sets'
                        ? 'Boxset Purchase' : 'Single Purchase',
                    'Confirmation Page');
                this._basketItemsToTrack = {};
                this._addToBasket(action.mediaItemId, action.mediaItemDTOCollection);
                break;

            // on fast checkout bundle offer success
            case ActionTypes.REQUEST_FAST_CHECKOUT_BUNDLE_OFFER_SUCCESS:
                waitFor([MediaStore.dispatchToken]);
                this._basketItemsToTrack = {};
                if (action.primaryMediaItemIds) {
                    action.primaryMediaItemIds.forEach(id => this._addToBasket(id, action.mediaItemDTOCollection));
                }
                break;

            // on fast checkout bundle offer error
            // on get bundle shopping cart value error
            case ActionTypes.REQUEST_FAST_CHECKOUT_BUNDLE_OFFER_ERROR:
            case ActionTypes.REQUEST_GET_BUNDLE_SHOPPING_CART_VALUE_ERROR:
                waitFor([MediaStore.dispatchToken]);
                this._event('purchase', 'Error Checkout Bundle Offer', action.collection.title);
                this.emitUpdate();
                break;

            // cancel checkout
            case ActionTypes.CANCEL_CHECKOUT:
                if (this._lastPurchaseType === 'MovieBoxSet' || this._lastPurchaseType === 'TVBoxSet') {
                    this._event('Purchase', 'Boxset Purchase', 'Cancel');
                } else {
                    this._event('Purchase', 'Single Purchase', 'Cancel');
                }
                this.emitUpdate();
                break;

            // on charge basket success
            case ActionTypes.REQUEST_CHARGE_BASKET_SUCCESS:
                waitFor([PurchaseStore.dispatchToken]);
                this._singleItemTransaction(action.amoutDue, action.entitlementDTOCollection);
                if (this._lastPurchaseType === 'MovieBoxSet' || this._lastPurchaseType === 'TVBoxSet') {
                    this._event('Purchase', 'Boxset Purchase', 'Confirmed Purchase');
                } else {
                    this._event('Purchase', 'Single Purchase', 'Confirmed Purchase');
                }
                this.emitUpdate();
                break;

            // on charge basket error
            case ActionTypes.REQUEST_CHARGE_BASKET_ERROR:
                waitFor([PurchaseStore.dispatchToken]);
                if (this._lastPurchaseType === 'MovieBoxSet' || this._lastPurchaseType === 'TVBoxSet') {
                    this._event('Purchase', 'Boxset Purchase', 'Payment Error');
                } else {
                    this._event('Purchase', 'Single Purchase', 'Payment Error');
                }
                this.emitUpdate();
                break;

            // on user accessed stb pairing page
            case ActionTypes.REQUEST_STB_PAIRING_PROCESS_ENABLED:
                waitFor([EntitlementStore.dispatchToken]);
                this._event('STB Pairing', 'Landing', 'Landing Page');
                this.emitUpdate();
                break;

            // on confirm stb registration success
            case ActionTypes.REQUEST_CONFIRM_STB_REGISTRATION_SUCCESS:
                waitFor([EntitlementStore.dispatchToken]);
                action.registered
                    ? this._event('STB Pairing', 'Pairing', 'Pairing Successful')
                    : this._event('STB Pairing', 'Pairing', 'Pairing Unsuccessful');

                this.emitUpdate();
                break;

            // on EntitlementStore: media item playback/download
            case EntitlementStore.START_MEDIA_ITEM_PLAYBACK:
                waitFor([EntitlementStore.dispatchToken]);
                this._pageView(`/${action.playerAction}/${action.mediaItem.id}`);
                if (action.playerAction === 'play') this._event('stream', 'play', action.descriptiveMediaTitle || action.mediaItem.sortTitle);
                if (action.playerAction === 'download') this._event('download', 'success', action.descriptiveMediaTitle || action.mediaItem.sortTitle);
                this.emitUpdate();
                break;

            // on WatchlistStore: add media item to watchlist
            case ActionTypes.REQUEST_ADD_MEDIA_ITEM_TO_WATCHLIST:
                waitFor([WatchlistStore.dispatchToken]);
                this._event('Feature', 'Wish List', 'Add to Wish List' + action.mediaItem.title);
                this.emitUpdate();
                break;

            default:
                break;
        }
    }

    /**
     * Get _error
     *
     * @returns {null|*}
     */
    get error() {
        return this._error;
    }

    /**
     * Import item info into basket for GA tracking
     *
     * @params {string} mediaItemId,
     * @params {array} mediaItemDTOCollection
     */
    _addToBasket(mediaItemId, mediaItemDTOCollection) {
        let mediaItem = null;
        let title = '';
        let classification = '';
        if (mediaItemId) {
            mediaItem = mediaItemDTOCollection.find(mediaItemDTO => mediaItemId === mediaItemDTO.SupplierReference);
            title = mediaItem.Title;
            this._lastPurchaseType = mediaItem.Classification;
            while (mediaItem.ParentId) {
                mediaItem = mediaItemDTOCollection.find(mediaItemDTO => mediaItem.ParentId === mediaItemDTO.SupplierReference);
                title = `${mediaItem.Title} / ${title}`;
                classification = mediaItem.Classification;
            }
            this._basketItemsToTrack[mediaItemId] = {
                title,
                classification
            };
        }
    }

    /**
     * (Re)initialize ga instance
     *
     * @params {string} userId
     */
    _initializeGASession(userId = null) {
        this._ga = ua(Config.GA_TRACKING_ID, AppStore.gaCID, {https: true});
        if (userId) {
            // one way hash of user's email
            // temporary solution for lack of unique unidentifiable userId
            userId = crypto.createHash('md5')
                .update(userId)
                .digest("hex")
                .replace(/(\w{8})(\w{4})(\w{4})(\w{4})(\w{12})/, "$1-$2-$3-$4-$5");
            this._ga.set("uid", userId);
        }
    }

    /**
     * Track page view
     *
     * @params {string} pathname
     */
    _pageView(pathname) {
        this._ga.pageview({
            dp: pathname,
            dt: this._getPageTitleFromPathname(pathname),
            dh: Config.BASE_URL,
            dr: document.referrer
        }).send();

        VubiquityAnalytics.pageView(pathname);
    }

    /**
     * Track event
     */
    _event(category, action, label = '', value = '', payload = null) {
        let params = payload ? {} : {}; // no op
        this._ga.event(category, action, label, value, params).send();

        VubiquityAnalytics.event(category, action, label);
    }

    /**
     * Track single item transaction
     */
    _singleItemTransaction(basketAmountDue, entitlements) {

        // ti - transaction id
        // tr - transaction revenue
        // ta - transaction affiliation
        // ip - item price
        // iq - item quantity
        // ic - item code
        // in - item names
        if (entitlements) {
            const transaction = this._ga.transaction({ti: entitlements[0].PurchaseOrderId, tr: basketAmountDue, ta: Config.AFFILIATE_ID});
            entitlements.forEach((entitlement) => {
                let basketItemInfo = this._basketItemsToTrack[entitlement.SupplierReference];
                if (basketItemInfo) {
                    transaction.item({ip: entitlement.UnitPrice, iq: 1, ic: entitlement.SupplierReference, ti: entitlement.PurchaseOrderId, in: basketItemInfo.title});
                }
            });
            transaction.send();
            this._basketItemsToTrack = {};
        }
    }

    /**
     * Track custom (Vubiquity) event
     */
    _customEvent(customEventType, params = {}) {
        VubiquityAnalytics.customEvent(customEventType, params);
    }

    /**
     * Get page title from pathname
     */
    _getPageTitleFromPathname(pathname) {
        if (pathname === "/" || pathname.indexOf('home') > -1) return 'Home';
        if (pathname.indexOf('movies') > -1 || pathname.indexOf('series') > -1
            || pathname.indexOf('seasons') > -1 || pathname.indexOf('episodes') > -1) return 'Product Detail Page';
        if (pathname.indexOf('collections') > -1) return 'Collection Page';
        if (pathname.indexOf('genres') > -1) return 'Genre Page';
        if (pathname.indexOf('search') > -1) return 'Search Page';
        if (pathname.indexOf('play') > -1) return 'Player';
        if (pathname.indexOf('sign-up') > -1) return 'Sign Up';
        if (pathname.indexOf('sign-in') > -1) return 'Sign In';
        if (pathname.indexOf('pages/contact') > -1) return 'Contact';
        if (pathname.indexOf('pages/help') > -1) return 'Help';
        if (pathname.indexOf('watchlist') > -1) return 'Watchlist';
        return '';
    }

    /**
     * Get last purchase type
     */
    getCurrentBasketItem() {
        return this._lastPurchaseType;
    }
}

export default new AnalyticsStore();
