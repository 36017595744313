"use strict";

import React from 'react';
import PropTypes from 'prop-types';
import ReactDOM from 'react-dom';
import shallowCompare from 'react-addons-shallow-compare';
import ImmutablePropTypes from 'react-immutable-proptypes';
import moment from 'moment';
import 'moment-duration-format';
import uuidGenerator from 'uuid';
import Config from '../../../lib/Config';
import Router from '../../../lib/Router';
import '../../../lib/Screenfull';
import { debounce, throttle } from '../../../utils/Common';
import MediaItem, { MediaItemClassification } from '../../../types/MediaItem';
import EntitlementActions from '../../../actions/EntitlementActions';
import AnalyticsActions from '../../../actions/AnalyticsActions';
import { VubiquityCustomEventType } from '../../../lib/VubiquityAnalytics';
import AppStore from '../../../stores/AppStore';
import MediaStore from '../../../stores/MediaStore';
import EntitlementStore from '../../../stores/EntitlementStore';
import ShakaMediaPlayer from "../../../lib/ShakaMediaPlayer";
import Spinner from '../../ui/Spinner2.react';

// initialize Shaka media player
ShakaMediaPlayer.initialize();
/**
 * ShakaPlayer component
 */
class ShakaPlayer extends React.Component {
    /**
     * React: propTypes
     */
    static propTypes = {
        mediaPlayerInstanceId: PropTypes.string,
        mediaItem: PropTypes.instanceOf(MediaItem).isRequired,
        seriesMediaItem: PropTypes.instanceOf(MediaItem),
        playlistMedia: ImmutablePropTypes.mapOf(PropTypes.instanceOf(MediaItem)),
        playerAction: PropTypes.oneOf(['play', 'trailer']),
        displayControls: PropTypes.bool,
        autoPlay: PropTypes.bool,
        volume: PropTypes.number,
        closePlayer: PropTypes.func
    };

    /**
     * React: defaultProps
     */
    static defaultProps = {
        displayControls: true,
        autoPlay: true,
        volume: Config.MEDIA_PLAYER_VOLUME
    };

    /**
     * React: contextTypes
     */
    static contextTypes = {
        router: PropTypes.object
    };

    /**
     * React: state
     */
    state = {
        videoMediaItem: null,
        amsLicense: null,
        previousVideoMediaItem: null,
        isPlayerReady: null,
        currentTime: null,
        paused: true,
        volume: this.props.volume,
        isFullScreenActive: false,
        isPlaylistActive: false,
        isSubtitleListActive: false,
        areControlsHidden: false,
        controlsToggleTimeoutId: 0,
        resumePlaybackDialogEnabled: false,
        registeredDevices: null,
        newDeviceDetectedDialogEnabled: false,
        isDevicesLimitExceeded: false,
        isStreamLimitExceeded: false,
        errorMessage: null
    };

    /**
     * Player instance and uuid
     *
     * @type {?string}
     * @private
     */
    _player = null;
    video = null;
    _playerUUID = null;

    /**
     * Constructor
     */
    constructor(props, context) {
        super(props, context);
        this._playerUUID = uuidGenerator.v4();
        this._timeControlsToggle = debounce(this._timeControlsToggle, 200, true);
        this._onTimeUpdate = throttle(this._onTimeUpdate, 1000);
        this._onTimeUpdateCreatePlaybackLog = throttle(this._onTimeUpdateCreatePlaybackLog, 30000);

        let mediaItem = this.props.mediaItem;
        this.state = {
            ...this.state,
            videoMediaItem: mediaItem.classification === MediaItemClassification.SEASON
                ? MediaStore.getMediaBucket(mediaItem.bucketKey).mediaItemChildren(mediaItem).first() : mediaItem
        };
    }

    /**
     * React: componentDidMount
     */
    componentDidMount() {
        this.initializePlayer();
        if (this._player) {
            this._addPlayerEventListeners();
            this._setPlayerVideoSource();
        }
        document.addEventListener('click', this._closeDropDowns, false);
        document.addEventListener('keydown', this._handleKeyPress, false);
        if (screenfull.enabled) screenfull.onchange(this._setFullScreenState);
    }

    /**
     * React: shouldComponentUpdate
     *
     * @param nextProps
     * @param nextState
     * @return {boolean}
     */
    shouldComponentUpdate(nextProps, nextState) {
        return shallowCompare(this, nextProps, nextState);
    }

    /**
     * React: componentWillUnmount
     */
    componentWillUnmount() {
        this._removePlayerEventListeners();
        if (this.props.playerAction === 'play') {
            EntitlementStore.removeUpdateListener(this._validateRegisteredDevicesSlotCount);
            EntitlementStore.removeUpdateListener(this._setAMSLicenseVideoSource);
            EntitlementStore.removeUpdateListener(this._stopPlaybackOnLogResponse);
        }
        this._player.destroy();
        this._player = null;

        document.removeEventListener('click', this._closeDropDowns, false);
        document.removeEventListener('keydown', this._handleKeyPress, false);
        if (screenfull.enabled) screenfull.unsubscribe(this._setFullScreenState);

        EntitlementStore.removePlayerAMSLicense(this.state.videoMediaItem.id);
        this.setState({
            videoMediaItem: null,
            amsLicense: null,
            isPlayerReady: null
        });
    }

    initializePlayer = () => {
        const video = document.getElementById('shakamediaplayer');
        const videoContainer = document.getElementById('videoContainer');
        this._player = shaka.polyfill.installAll();
        this._player = new shaka.Player();
        // const ui = new shaka.ui.Overlay(this._player, videoContainer, video);
        // const playerControls = ui.getControls();
        this.video = video;
        if (shaka.Player.isBrowserSupported()) {
            this._player.attach(video);
        }
    }
    /**
     * React: render
     */
    render() {
        let videoMediaItem = this.state.videoMediaItem;
        let playlistMedia = this.props.playlistMedia;
        let timePercent = this.state.currentTime / Math.floor(this._getVideoSourceDuration());

        return (
            <div
                className={`azure-player ${this.state.isFullScreenActive ? 'full-screen' : ''} ${this.state.areControlsHidden ? ' hidden-controls' : ''}`}>
                <div className="shaka-player">
                    <video id="shakamediaplayer" className="azuremediaplayer">
                        {AppStore.translate('view.media_player.message.not_supported')}
                    </video>
                </div>
                {!this.state.isPlayerReady ? (
                    <div className="mpa-loading">
                        <Spinner />
                    </div>
                ) : null}

                {this.state.isPlayerReady
                    && !this.state.resumePlaybackDialogEnabled ? (
                    <div className="mpa-controls">
                        <style dangerouslySetInnerHTML={{
                            __html: `
                            .mpa-controls-slider input[value="${this.state.currentTime}"]::-webkit-slider-runnable-track {
                                background: linear-gradient(to right, #FFFFFF 0%, #FFFFFF ${timePercent * 100}%, #000000 ${timePercent * 100}%, #000000 100%);
                            }
                        `}} />
                        <div className="mpa-controls-bar mpa-controls-top">
                            <div className="controls-title">
                                {videoMediaItem.classification === MediaItemClassification.EPISODE && this.props.playerAction !== 'trailer' ?
                                    <div>
                                        <span className="grey-text">
                                            S{videoMediaItem.seasonNumber} E{videoMediaItem.numberInSequence}
                                        </span> {this.props.seriesMediaItem.title} "{videoMediaItem.title}"
                                    </div>
                                    : this.props.playerAction !== 'trailer' ? videoMediaItem.title : ''}
                            </div>
                            {playlistMedia &&
                                <div className="controls-playlist">
                                    <button
                                        className={`mpa-controls-button ${this.state.isPlaylistActive ? 'active' : ''}`}
                                        onClick={this._togglePlaylist}
                                        ref="playlistButton">
                                        <i className="ui-icon icon-mp_episodes" />
                                    </button>
                                </div>}
                            <div className="controls-volume mpa-controls-slider mpa-controls-slider--percent">
                                <input type="range" value={this.state.volume} min="0" max="100" step="1"
                                    onMouseUp={this._setVolume}
                                    onChange={this._setVolume} /><i className={`ui-icon ${this.state.volume == 0 ? 'icon-mp_volume_speaker_mute' : 'icon-mp_volume_speaker'}`} />
                            </div>
                            {screenfull.enabled ? (
                                <div className="controls-full-screen">
                                    <button className="mpa-controls-button" onClick={this._toggleFullScreen}>
                                        <i className={`ui-icon ${this.state.isFullScreenActive ? 'icon-mp_shrink' : 'icon-mp_expand'}`} />
                                    </button>
                                </div>
                            ) : null}
                        </div>
                        <div className="mpa-controls-middle" onClick={this._togglePlayback}>
                        </div>
                        <div className="mpa-controls-bar mpa-controls-bottom">
                            <div className="controls-current-time">
                                <span className="time-white">
                                    {moment.duration(this.state.currentTime, "seconds").format('h:mm:ss', {
                                        trim: "left large",
                                        stopTrim: "m"
                                    })}
                                </span>
                                <span>&nbsp;/&nbsp;</span>
                                <span className="time-grey">
                                    {moment.duration(Math.floor(this._getVideoSourceDuration()), "seconds").format('h:mm:ss', {
                                        trim: "left large",
                                        stopTrim: "m"
                                    }
                                    )}
                                </span>
                            </div>
                            <div className="controls-play">
                                <button className="mpa-controls-button" onClick={this._togglePlayback}>
                                    <i className={`ui-icon ${this.state.paused ? 'icon-mp_play' : 'icon-mp_pause'}`} />
                                </button>
                            </div>
                            <div className="controls-progress-bar mpa-controls-slider">
                                <input type="range"
                                    min="0"
                                    step="1"
                                    max={this._getVideoSourceDuration()}
                                    value={this.state.currentTime}
                                    onMouseUp={this._setCurrentTime}
                                    onChange={this._setCurrentTime} />
                            </div>
                            {this._getNextMediaItemToPlay() &&
                                <div className="controls-full-screen">
                                    <button className="mpa-controls-button-label " onClick={this._setVideoMediaItem.bind(null, this._getNextMediaItemToPlay())}>
                                        <i className="ui-icon icon-mp_play_next" /><span>Next episode</span>
                                    </button>
                                </div>}
                        </div>
                    </div>
                ) : null}

                {this.state.isPlayerReady
                    && !this.state.resumePlaybackDialogEnabled
                    && this.state.isPlaylistActive
                    && playlistMedia ? (
                    <div className="mpa-playlist" ref="playlist">
                        <div className="mpa-playlist-container">
                            <div className="mpa-playlist-head">SEASON {videoMediaItem.seasonNumber} ({playlistMedia.size} EPISODES)</div>
                            <ul>
                                {playlistMedia.toArray().map((playlistMediaItem, index) => {
                                    return (
                                        <li key={playlistMediaItem.id}>
                                            <div className="mpa-playlist-item-wrapper">
                                                <div className="mpa-playlist-title">
                                                    {index + 1}. {playlistMediaItem.title}
                                                </div>
                                                <div className="mpa-playlist-duration">
                                                    {moment.duration(parseInt(playlistMediaItem.duration), "minutes").format('hh:mm:ss', { trim: false })}
                                                </div>
                                                <div className="mpa-playlist-action">

                                                    <button className="active"
                                                        disabled={!playlistMediaItem.isPurchased}
                                                        onClick={playlistMediaItem === videoMediaItem
                                                            ? this._togglePlayback
                                                            : this._setVideoMediaItem.bind(null, playlistMediaItem)}>
                                                        {playlistMediaItem === videoMediaItem ? (
                                                            playlistMediaItem.isPurchased && <i className={`ui-icon ${this.state.paused ? 'icon-mp_play' : 'icon-mp_pause'}`} />
                                                        ) : (
                                                            playlistMediaItem.isPurchased && <i className="ui-icon icon-mp_play" />
                                                        )}
                                                    </button>
                                                </div>
                                                <div className="mpa-playlist-action">
                                                    <button
                                                        onClick={() => this.context.router.push(Router.MEDIA_ITEM(playlistMediaItem))}>
                                                        <i className="ui-icon icon-details" />
                                                    </button>
                                                </div>
                                            </div>
                                            <div className="mpa-playlist-progress">
                                                {playlistMediaItem !== videoMediaItem
                                                    && EntitlementStore.currentEntitlementsByMediaItem.get(playlistMediaItem.id) ? (
                                                    <div className="mpa-playlist-progress-bar">
                                                        <span style={
                                                            { width: (playlistMediaItem.watchedPercentage || 0) + '%' }
                                                        } />
                                                    </div>
                                                ) : null}
                                            </div>
                                        </li>
                                    );
                                })}
                            </ul>
                        </div>
                    </div>
                ) : null}

                {this.state.resumePlaybackDialogEnabled ? (
                    <div className="mpa-dialog-backdrop">
                        <div className="mpa-dialog">
                            <h2 className="mpa-dialog__title">Note</h2>
                            <div className="mpa-dialog__content">
                                <p>{AppStore.translate('view.media_player.message.resume_playback_available')}</p>
                                <button className="mpa-controls-button" onClick={this._resumePlaybackFromBeginning}>
                                    {AppStore.translate('view.media_player.controls.start_from_beginning')}
                                </button>
                                <button className="mpa-controls-button mpa-primary-button"
                                    onClick={this._scheduleResumePlaybackFromLastPlayPosition}>
                                    {AppStore.translate('view.media_player.controls.resume_from_last_play_position')}
                                </button>
                            </div>
                        </div>
                    </div>
                ) : null}

                {this.state.newDeviceDetectedDialogEnabled ? (
                    <div className="mpa-dialog-backdrop">
                        <div className="mpa-dialog">
                            <h2 className="mpa-dialog__title">NOTE</h2>
                            <div className="mpa-dialog__content">
                                <h3>{AppStore.translate('view.media_player.title.new_device_detected')}</h3>
                                <p>{AppStore.translate('view.media_player.message.add_device', {
                                    registeredDevicesFreeSlotCount: Config.REGISTERED_DEVICE_LIMIT - EntitlementStore.registeredDevices.size
                                })}</p>
                                <button className="mpa-controls-button" onClick={this._cancelPlayerActions}>
                                    {AppStore.translate('view.media_player.controls.cancel')}
                                </button>
                                <button className="mpa-controls-button mpa-primary-button"
                                    onClick={this._registerNewDevice}>
                                    {AppStore.translate('view.media_player.controls.continue')}
                                </button>
                            </div>
                        </div>
                    </div>
                ) : null}

                {this.state.isDevicesLimitExceeded ? (
                    <div className="mpa-dialog-backdrop">
                        <div className="mpa-dialog">
                            <h2 className="mpa-dialog__title">NOTE</h2>
                            <div className="mpa-dialog__content">
                                <h3>{AppStore.translate('view.media_player.title.device_limit_exceeded')}</h3>
                                <p className="mpa-warning">{AppStore.translate('view.media_player.message.device_limit_status', {
                                    registeredDevicesCount: EntitlementStore.registeredDevices.size
                                })}</p>
                                <p>{AppStore.translate('view.media_player.message.device_limit_exceeded')}</p>
                                <button className="mpa-controls-button" onClick={this._cancelPlayerActions}>
                                    {AppStore.translate('view.media_player.controls.cancel')}
                                </button>
                                <button className="mpa-controls-button mpa-primary-button"
                                    onClick={() => this.context.router.push(Router.MY_DEVICES)}>
                                    {AppStore.translate('view.media_player.controls.settings')}
                                </button>
                            </div>
                        </div>
                    </div>
                ) : null}

                {this.state.isStreamLimitExceeded ? (
                    <div className="mpa-dialog-backdrop">
                        <div className="mpa-dialog">
                            <h2 className="mpa-dialog__title">{AppStore.translate('view.media_player.title.stream_limit_exceeded')}</h2>
                            <div className="mpa-dialog__content">
                                <p>{AppStore.translate('view.media_player.message.stream_limit_exceeded')}</p>
                                <button className="mpa-controls-button" onClick={this._cancelPlayerActions}>
                                    {AppStore.translate('view.media_player.controls.close')}
                                </button>
                            </div>
                        </div>
                    </div>
                ) : null}

                {this.state.errorMessage ? (
                    <div className="mpa-dialog-backdrop">
                        <div className="mpa-dialog">
                            <h2 className="mpa-dialog__title">{AppStore.translate('view.media_player.title.error')}</h2>
                            <div className="mpa-dialog__content">
                                <p>{AppStore.translate('view.media_player.message.something_went_wrong', {
                                    errorMessage: Config.ENVIRONMENT === 'development' || Config.ENVIRONMENT === 'testing'
                                        ? this.state.errorMessage : null
                                })}</p>
                                <button className="mpa-controls-button" onClick={this._cancelPlayerActions}>
                                    {AppStore.translate('view.media_player.controls.close')}
                                </button>
                            </div>
                        </div>
                    </div>
                ) : null}
            </div>
        );
    }

    /**
     * Add player event listeners
     *
     * @private
     */
    _addPlayerEventListeners = () => {
        this._player.addEventListener('loaded', this._onSourceSet);
        this.video.addEventListener('play', this._onPlay);
        this.video.addEventListener('timeupdate', this._onTimeUpdate);
        this.video.addEventListener('timeupdate', this._onTimeUpdateCreatePlaybackLog);
        this.video.addEventListener('pause', this._onPause);
        this.video.addEventListener('waiting', this._onWaiting);
        this.video.addEventListener('ended', this._onEnded);
        this.video.addEventListener('error', this._onError);
    };

    /**
     * Remove player event listeners
     *
     * @private
     */
    _removePlayerEventListeners = () => {
        this._player.removeEventListener('loaded', this._onSourceSet);
        this._player.removeEventListener('play', this._onPlay);
        this.video.removeEventListener('timeupdate', this._onTimeUpdate);
        this.video.removeEventListener('timeupdate', this._onTimeUpdateCreatePlaybackLog);
        this.video.removeEventListener('pause', this._onPause);
        this.video.removeEventListener('waiting', this._onWaiting);
        this.video.removeEventListener('ended', this._onEnded);
        this.video.removeEventListener('error', this._onError);
    };

    /**
     * On player 'sourceset'
     *
     * @private
     */
    _onSourceSet = () => {
        if (!this._player) return;

        let videoEntitlement = EntitlementStore.currentEntitlementsByMediaItem.get(this.state.videoMediaItem.id);
        this.setState({
            isPlayerReady: true,
            currentTime: 0,
            resumePlaybackDialogEnabled: this.props.playerAction === 'play' && this.props.autoPlay && videoEntitlement && videoEntitlement.lastPlayPosition
        }, () => {
            setTimeout(() => {
                // this._player.volume(this.state.volume * 0.01);
                if (this.props.autoPlay && !this.state.resumePlaybackDialogEnabled) {
                    this.video.play();
                    this._logCustomEvent(VubiquityCustomEventType.LOG_PLAYBACK_START);
                }
            }, 1500);
        });
    };

    /**
     * On player 'play'
     *
     * @private
     */
    _onPlay = () => {
        if (!this._player) return;

        clearTimeout(this.state.controlsToggleTimeoutId);
        this.setState({
            controlsToggleTimeoutId: setTimeout(this._hideControls, Config.MEDIA_PLAYER_CONTROLS_AUTO_HIDE)
        }, () => {
            this._createPlaybackLog();
            let shakaPlayerNode = ReactDOM.findDOMNode(this);
            shakaPlayerNode.addEventListener('mousemove', this._timeControlsToggle);
            this._logCustomEvent(VubiquityCustomEventType.LOG_PLAYBACK_QUALITY, {
                bitrate: this._player.getMediaElement().currentPlaybackBitrate
            });
        });
    };

    /**
     * On player 'timeupdate'
     *
     * @private
     */
    _onTimeUpdate = () => {
        if (!this._player) return;
        this.setState({
            currentTime: this._player.getMediaElement().currentTime,
            paused: this._player.getMediaElement().paused
        });
    };

    /**
     * On player 'timeupdate'
     *
     * @private
     */
    _onTimeUpdateCreatePlaybackLog = () => {
        if (!this._player) return;

        this._createPlaybackLog();
    };

    /**
     * On player 'pause'
     *
     * @private
     */
    _onPause = () => {
        if (!this._player) return;

        clearTimeout(this.state.controlsToggleTimeoutId);
        this.setState({
            areControlsHidden: false,
            controlsToggleTimeoutId: 0
        }, () => {
            this._createPlaybackLog();
            let shakaPlayerNode = ReactDOM.findDOMNode(this);
            shakaPlayerNode.removeEventListener('mousemove', this._timeControlsToggle);
        });
    };

    /**
     * On player 'waiting'
     *
     * @private
     */
    _onWaiting = () => {
        if (!this._player) return;

        this._logCustomEvent(VubiquityCustomEventType.LOG_PLAYBACK_BUFFERING);
    };

    /**
     * On player 'ended'
     *
     * @private
     */
    _onEnded = () => {
        if (!this._player) return;
        EntitlementActions.stopMediaItemPlayback();
        this._logCustomEvent(VubiquityCustomEventType.LOG_PLAYBACK_STOP);
        this.props.closePlayer();
    };

    /**
     * On player 'error'
     *
     * @private
     */
    _onError = () => {
        if (!this._player) return;

        let mediaError = this._player.getMediaElement().error;
        this.setState({
            errorMessage: mediaError.message
        }, () => {
            this._logCustomEvent(VubiquityCustomEventType.LOG_PLAYBACK_FAULT);
        });
    };

    /**
     * Set player video source
     *
     * @private
     */
    _setPlayerVideoSource = () => {
        let videoMediaItem = this.state.videoMediaItem;

        if (this.props.playerAction === 'trailer') {
            setTimeout(() => { // defer to prevent dispatch errors in case mounting happened as part of action
                this._getTrailerLicense(this.props.mediaItem);
            }, 0);
        }

        if (this.props.playerAction === 'play' && videoMediaItem) {
            setTimeout(() => { // defer to prevent dispatch errors in case mounting happened as part of action
                EntitlementStore.addUpdateListener(this._validateRegisteredDevicesSlotCount);
                EntitlementActions.getRegisteredDevices({ includeInactiveDevices: false });
                EntitlementStore.addUpdateListener(this._setAMSLicenseVideoSource);
                EntitlementStore.addUpdateListener(this._stopPlaybackOnLogResponse);
            }, 0);
        }
    };

    /**
     * Set trailer video source
     *
     * @private
     */
    _setTrailerVideoSource = async () => {
        EntitlementStore.DisableTrailerStatus;
        let videoMediaItem = this.state.videoMediaItem;
        let amsLicense = EntitlementStore.playerTrailerLicense.get();
        if (amsLicense) {
            this._player.configure({
                drm: {
                    servers: {
                        'com.widevine.alpha': amsLicense.licenseKeys[0].laurl,
                        'com.microsoft.playready': amsLicense.licenseKeys[1].laurl,
                        "com.apple.fps": amsLicense.licenseKeys[2].laurl
                    }
                }
            });
            if (this._isIOS()) {
                this._player.configure('drm.advanced.com\\.apple\\.fps.serverCertificateUri',
                    amsLicense.licenseKeys[3].certificateUrl);
                let manifest = amsLicense.source.replace("DASH", "HLS").replace("manifest.mpd", "index.m3u8");
                await this._player.load(amsLicense.source);
            } else {
                await this._player.load(amsLicense.source);
            }
        };
    }

        _isIOS = () => {
            return (
                ['iPad Simulator', 'iPhone Simulator', 'iPod Simulator', 'iPad', 'iPhone', 'iPod'].includes(navigator.platform) ||
                (navigator.userAgent.includes('Mac') && 'ontouchend' in document)
            );
        }

        loadLicenseVideoSource = async (amsLicense) => {
            this._player.configure({
                drm: {
                    servers: {
                        'com.widevine.alpha': amsLicense.licenseKeys[0].laurl,
                        'com.microsoft.playready': amsLicense.licenseKeys[1].laurl,
                        "com.apple.fps": amsLicense.licenseKeys[2].laurl
                    }
                }
            });

            try {
                let videoMediaItem = this.state.videoMediaItem;
                let playbackRecent = EntitlementStore.recentlyViewedItems.get(videoMediaItem.id);
                let a = playbackRecent.lastPlayPosition.split(':');
                let playbackPositionSeconds = (+a[0]) * 60 * 60 + (+a[1]) * 60 + (+a[2]);
                if (this._isIOS()) {
                    loadFairplayLicense(amsLicense, playbackPositionSeconds);
                } else {
                    const seekRange = this._player.seekRange();
                    this._player.getMediaElement().currentTime = playbackPositionSeconds;
                    await this._player.load(amsLicense.source);
                }
            } catch (e) {
                this.onError(e);
            }

        }

        loadFairplayLicense = async (amsLicense, playbackPositionSeconds) => {
            this._player.configure('drm.advanced.com\\.apple\\.fps.serverCertificateUri',
                amsLicense.licenseKeys[3].certificateUrl);
            let manifest = amsLicense.source.replace("DASH", "HLS").replace("manifest.mpd", "index.m3u8");
            const seekRange = this._player.seekRange();
            this._player.getMediaElement().currentTime = playbackPositionSeconds;
            await this._player.load(manifest);

        }
        /**
         * Set AMSLicense video source
         *
         * @private
         */
        _setAMSLicenseVideoSource = () => {
            if (!this._player) return;

            let videoMediaItem = this.state.videoMediaItem;
            let amsLicense = EntitlementStore.playerAMSLicenses.get(videoMediaItem.id);

            if (amsLicense && this.state.amsLicense !== amsLicense) {
                this.setState({
                    amsLicense: amsLicense,
                    previousVideoMediaItem: null
                }, () => {
                    this.loadLicenseVideoSource(amsLicense);
                });
                return;
            }

            if (!amsLicense && EntitlementStore.error) {
                // continue playing previous video if there is one
                if (this.state.previousVideoMediaItem) {
                    this.setState({
                        isPlayerReady: true,
                        videoMediaItem: this.state.previousVideoMediaItem,
                        previousVideoMediaItem: null
                    }, () => {
                        this.video.play();
                    });
                    return;
                }

                if (EntitlementStore.error.message && EntitlementStore.error.message.indexOf('Streaming on too many devices') > -1) {
                    this.setState({
                        isStreamLimitExceeded: true
                    });
                    return;
                }

                this.setState({
                    errorMessage: EntitlementStore.error.message || "Unknown error."
                }, () => {
                    this._logCustomEvent(VubiquityCustomEventType.LOG_PLAYBACK_FAULT);
                });
            }
        };

        /**
         * Get AMS license
         *
         * @private
         */
        _getAMSLicense = (mediaItem) => {
            setTimeout(() => { // defer to prevent dispatch errors
                EntitlementActions.getAMSLicense({
                    mediaItemId: mediaItem.id,
                    mediaItemClassification: mediaItem.classification,
                    ipAddress: '89.216.113.253', // AuthStore.identity.userIP,
                    deviceId: AppStore.deviceId,
                    sessionId: AppStore.sessionId,
                    persistentLicenseRequested: false,
                    deviceType: AppStore.deviceType,
                    keyId: '00000000-0000-0000-0000-00000000'
                });
            }, 0);
        };


        /**
        * Get Trailer license
        *
        * @private
        */
        _getTrailerLicense = (mediaItem) => {
            EntitlementStore.SetTrailerStatus;
            setTimeout(() => { // defer to prevent dispatch errors
                EntitlementActions.getTrailerLicense({
                    offerId: mediaItem.offers[0].id
                });            
                EntitlementStore.addUpdateListener(this._setTrailerVideoSource);
            }, 0);
        };

        /**
         * Validate registered devices slot count
         *
         * @private
         */
        _validateRegisteredDevicesSlotCount = () => {
            if (this.state.amsLicense) return;
            if (!EntitlementStore.registeredDevices || this.state.registeredDevices) return;
            let registeredDevices = EntitlementStore.registeredDevices;

            this.setState({
                registeredDevices: registeredDevices,
                newDeviceDetectedDialogEnabled: !registeredDevices.get(AppStore.deviceId) && registeredDevices.size !== Config.REGISTERED_DEVICE_LIMIT,
                isDevicesLimitExceeded: !registeredDevices.get(AppStore.deviceId) && registeredDevices.size === Config.REGISTERED_DEVICE_LIMIT
            }, () => {
                if (!this.state.newDeviceDetectedDialogEnabled && !this.state.isDevicesLimitExceeded) {
                    this._getAMSLicense(this.state.videoMediaItem);
                    EntitlementStore.addUpdateListener(this._setAMSLicenseVideoSource);
                }
            });
        };

        /**
         * Registered new device (load AMS license for videoMediaItem)
         *
         * @private
         */
        _registerNewDevice = () => {
            this.setState({
                newDeviceDetectedDialogEnabled: false
            }, () => {
                this._getAMSLicense(this.state.videoMediaItem);
            });
        };

        /**
         * Cancel player actions
         *
         * @private
         */
        _cancelPlayerActions = () => {
            this.setState({
                newDeviceDetectedDialogEnabled: false,
                isDevicesLimitExceeded: false,
                isStreamLimitExceeded: false,
                errorMessage: 'No available actions.'
            }, () => {
                window.mediaPlayerStopPlayback();
                window.stopMediaItemPlayback();
                this._logCustomEvent(VubiquityCustomEventType.LOG_PLAYBACK_FAULT);
            });
        };

        /**
         * Stop playback on log response
         *
         * @private
         */
        _stopPlaybackOnLogResponse = () => {
            if (!this._player) return;

            if (EntitlementStore.playbackLog && !EntitlementStore.playbackLog.continuePlayback) {
                this._player.pause();
                this._logCustomEvent(VubiquityCustomEventType.LOG_PLAYBACK_STOP);

                this.setState({
                    errorMessage: EntitlementStore.playbackLog.stopReason || "Unknown error."
                }, () => {
                    this._logCustomEvent(VubiquityCustomEventType.LOG_PLAYBACK_FAULT);
                });
            }
        };

        /**
         * Set videoMediaItem
         *
         * @private
         */
        _setVideoMediaItem = (videoMediaItem) => {
            if (!this._player) return;

            this.video.pause();
            this.setState({
                videoMediaItem: videoMediaItem,
                previousVideoMediaItem: this.state.videoMediaItem,
                isPlayerReady: false,
                isPlaylistActive: false
            }, () => {
                this._getAMSLicense(this.state.videoMediaItem);
            });
        };

        /**
         * Toggle playback
         *
         * @private
         */
        _togglePlayback = () => {
            this.setState({
                paused: !this.state.paused
            }, () => {
                this.state.paused
                    ? this.video.pause()
                    : this.video.play();

                this.state.paused
                    ? this._logCustomEvent(VubiquityCustomEventType.LOG_PLAYBACK_PAUSE)
                    : this._logCustomEvent(VubiquityCustomEventType.LOG_PLAYBACK_START);
            });
        };

        /**
         * Resume playback from beginning
         *
         * @private
         */
        _resumePlaybackFromBeginning = () => {
            this.setState({
                resumePlaybackDialogEnabled: false
            }, () => {
                this._player.getMediaElement().currentTime = 0;
                this.video.play();
                this._logCustomEvent(VubiquityCustomEventType.LOG_PLAYBACK_START);
            });
        };

        /**
         * Schedule resume playback from last play position
         *
         * @private
         */
        _scheduleResumePlaybackFromLastPlayPosition = () => {
            this.setState({
                resumePlaybackDialogEnabled: false
            }, () => {
                this.video.play();
                this._player.addEventListener('playing', this._resumePlaybackFromLastPlayPosition);
            });
        };

        /**
         * Resume playback from last play position
         *
         * @private
         */
        _resumePlaybackFromLastPlayPosition = () => {
            let videoEntitlement = EntitlementStore.currentEntitlementsByMediaItem.get(this.state.videoMediaItem.id);
            this._player.currentTime(moment.duration(videoEntitlement.lastPlayPosition).asSeconds());
            this._player.removeEventListener('playing', this._resumePlaybackFromLastPlayPosition);
            this._logCustomEvent(VubiquityCustomEventType.LOG_PLAYBACK_START);
        };

        /**
         * Get video source duration
         *
         * @returns {number}
         * @private
         */
        _getVideoSourceDuration = () => {
            if (!this._player) return 0;
            return this._player.seekRange() ? Math.round(this._player.seekRange().end) : 0;
        };

        /**
         * Get Next Media Item To Play for Episodes from Seasons in playlist
         *
         * @returns {number}
         * @private
         */
        _getNextMediaItemToPlay = () => {

            let videoMediaItem = this.state.videoMediaItem;
            let playlistMedia = this.props.playlistMedia;

            const purchasedMediaItemKeys = playlistMedia ? playlistMedia.filter(item => item.isPurchased).keySeq().toArray() : null;
            const nextMediaItemKey = purchasedMediaItemKeys ? purchasedMediaItemKeys[purchasedMediaItemKeys.indexOf(videoMediaItem.id) + 1] : null;

            return purchasedMediaItemKeys ? playlistMedia.get(nextMediaItemKey) : null;
        };

        /**
         * Set current time
         *
         * @private
         */
        _setCurrentTime = (event) => {
            let newTime = parseFloat(event.target.value);
            this.setState({
                currentTime: newTime
            }, () => {
                try {
                    this._player.getMediaElement().currentTime = newTime
                } catch (e) {
                    return null;
                }
            });
        };

        /**
         * Create playback log
         *
         * @private
         */
        _createPlaybackLog = () => {
            if (this.state.currentTime < 3) return; // prevents sending playback log on initial media load, which will
            // overwrite existing log with 0 in case where user doesn't start playback

            if (this.props.playerAction === 'play') {
                setTimeout(() => { // difer to prevent dispatch errors
                    EntitlementActions.createPlaybackLog({
                        mediaItemId: this.state.videoMediaItem.id,
                        currentTime: moment.duration(Math.floor(this.state.currentTime), "seconds").format('hh:mm:ss', { trim: false }),
                        sessionId: AppStore.sessionId
                    });
                }, 0);
            }
        };

        /**
         * Set volume
         *
         * @private
         * @param {Object} event
         */
        _setVolume = (event) => {
            let volume = event.target.value;
            this.setState({
                volume: volume
            }, () => {
                this.video.volume = volume * 0.01;
            });
        };

        /**
         * Toggle full screen
         *
         * @private
         */
        _toggleFullScreen = () => {
            let shakaPlayerNode = ReactDOM.findDOMNode(this);
            this.state.isFullScreenActive ? screenfull.exit() : screenfull.request(shakaPlayerNode);
        };

        /**
         * Set full screen state
         *
         * @private
         */
        _setFullScreenState = () => {
            this.setState({
                isFullScreenActive: screenfull.isFullscreen
            });
        };

        /**
         * Toggle playlist
         *
         * @private
         */
        _togglePlaylist = () => {
            this.setState({
                isPlaylistActive: !this.state.isPlaylistActive
            });
        };

        /**
         * Close drop downs on outside click
         *
         * @private
         */
        _closeDropDowns = (event) => {
            if (this.state.isPlaylistActive
                && !this.refs.playlistButton.contains(event.target)
                && !this.refs.playlist.contains(event.target)) {
                this.setState({
                    isPlaylistActive: false
                });
                return;
            }

            if (this.state.isSubtitleListActive
                && !this.refs.subtitlesButton.contains(event.target)
                && !this.refs.subtitles.contains(event.target)) {
                this.setState({
                    isSubtitleListActive: false
                });
            }
        };

        /**
         * Handle keypress for toggle playback on space-bar press
         *
         * @private
         */
        _handleKeyPress = (event) => {
            if (!AppStore.navigationSearchFocused && event.which === 32) {
                this._togglePlayback();
            }
        };

        /**
         * Time controls toggle
         *
         * @private
         */
        _timeControlsToggle = () => {
            clearTimeout(this.state.controlsToggleTimeoutId);
            this.setState({
                areControlsHidden: false,
                controlsToggleTimeoutId: setTimeout(this._hideControls, Config.MEDIA_PLAYER_CONTROLS_AUTO_HIDE)
            });
        };

        /**
         * Hide controls
         *
         * @private
         */
        _hideControls = () => {
            if (!this._player) return;

            this.setState({
                areControlsHidden: true
            });
        };

        /**
         * Log custom event
         *
         * @private
         */
        _logCustomEvent = (customEventType, customEventData = {}) => {
            if (this.props.playerAction === 'trailer') return; // don't log for trailers

            setTimeout(() => { // defer logging action dispatch in case some action in progress
                switch (customEventType) {
                    case VubiquityCustomEventType.LOG_PLAYBACK_BUFFERING:
                        AnalyticsActions.trackCustomEvent({
                            customEventType, params: {
                                durationInSeconds: 1
                            }
                        });
                        break;

                    case VubiquityCustomEventType.LOG_PLAYBACK_START:
                    case VubiquityCustomEventType.LOG_PLAYBACK_PAUSE:
                    case VubiquityCustomEventType.LOG_PLAYBACK_STOP:
                        AnalyticsActions.trackCustomEvent({
                            customEventType, params: {
                                mediaItemId: this.state.videoMediaItem.id,
                                playPosition: moment.duration(Math.floor(this.state.currentTime), "seconds").format('hh:mm:ss', { trim: false }),
                                playerDuration: moment.duration(Math.floor(this._getVideoSourceDuration()), "seconds").format('hh:mm:ss', { trim: false })
                            }
                        });
                        break;

                    case VubiquityCustomEventType.LOG_PLAYBACK_QUALITY:
                        AnalyticsActions.trackCustomEvent({
                            customEventType, params: {
                                bitrate: customEventData.bitrate
                            }
                        });
                        break;

                    case VubiquityCustomEventType.LOG_PLAYBACK_FAULT:
                        AnalyticsActions.trackCustomEvent({
                            customEventType, params: {
                                errorMessage: this.state.errorMessage,
                                mediaItemId: this.state.videoMediaItem.id,
                                playPosition: moment.duration(Math.floor(this.state.currentTime), "seconds").format('hh:mm:ss', { trim: false }),
                                playerDuration: moment.duration(Math.floor(this._getVideoSourceDuration()), "seconds").format('hh:mm:ss', { trim: false })
                            }
                        });
                        break;
                }
            }, 0);
        };

    }

export default ShakaPlayer;
