"use strict";

import {waitFor} from '../dispatchers/AppDispatcher';
import BaseStore from './BaseStore';
import Notification from '../types/Notification';
import {UserIdentityStatusType} from '../types/UserIdentity';
import {APIRequestError} from '../types/ExtendableError';
import StandardNotifications from '../lib/StandardNotifications';
import ActionTypes from '../actions/ActionTypes';
import AuthStore from '../stores/AuthStore';
import PurchaseStore from '../stores/PurchaseStore';
import EntitlementStore from '../stores/EntitlementStore';
import WatchlistStore from '../stores/WatchlistStore';

/**
 * Flux: NotificationStore
 */
class NotificationStore extends BaseStore {
    /**
     * _notification
     *
     * @type {Notification}
     * @private
     */
    _notification = null;
    _overlayNotification = null;

    /**
     * Constructor
     */
    constructor() {
        super();
        this.subscribe(() => this._registerToActions.bind(this));
    }

    /**
     * Flux: register store to actions
     *
     * @param action
     * @private
     */
    _registerToActions(action) {
        let userIdentityAffiliateStatus = null;
        this._error = null;

        switch (action.type) {
            // notification
            case ActionTypes.DISPLAY_NOTIFICATION:
                waitFor([EntitlementStore.dispatchToken]);
                this._notification = new Notification({
                    type: action.notificationType,
                    content: action.content,
                    hideAfter: action.hideAfter || 6000,
                    onClose: action.onClose
                });
                this.emitUpdate();
                break;

            case ActionTypes.HIDE_NOTIFICATION:
                this._notification = null;
                this._overlayNotification = null;
                this.emitUpdate();
                break;

            // sign up user & sign up then auto sign in
            case ActionTypes.REQUEST_SIGN_UP_USER_SUCCESS:
            case ActionTypes.REQUEST_SIGN_UP_USER_THEN_AUTO_SIGN_IN_SUCCESS:
                waitFor([AuthStore.dispatchToken]);
                this._notification = StandardNotifications.SIGN_UP_USER_SUCCESS();
                this.emitUpdate();
                break;

            case ActionTypes.REQUEST_SIGN_UP_USER_ERROR:
            case ActionTypes.REQUEST_SIGN_UP_USER_THEN_AUTO_SIGN_IN_ERROR:
                waitFor([AuthStore.dispatchToken]);
                this._notification = StandardNotifications.SIGN_UP_USER_ERROR(action.error);
                this.emitUpdate();
                break;

            // resend activation email
            case ActionTypes.REQUEST_RESEND_ACTIVATION_EMAIL_SUCCESS:
                waitFor([AuthStore.dispatchToken]);
                this._notification = StandardNotifications.RESEND_ACTIVATION_EMAIL_SUCCESS();
                this.emitUpdate();
                break;

            case ActionTypes.REQUEST_RESEND_ACTIVATION_EMAIL_ERROR:
                waitFor([AuthStore.dispatchToken]);
                this._notification = StandardNotifications.RESEND_ACTIVATION_EMAIL_ERROR(action.error);
                this.emitUpdate();
                break;

            // activate user
            case ActionTypes.REQUEST_ACTIVATE_USER_SUCCESS:
                waitFor([AuthStore.dispatchToken]);
                this._notification = StandardNotifications.ACTIVATE_USER_SUCCESS();
                this.emitUpdate();
                break;

            case ActionTypes.REQUEST_ACTIVATE_USER_ERROR:
                waitFor([AuthStore.dispatchToken]);
                this._notification = StandardNotifications.ACTIVATE_USER_ERROR(action.error);
                this.emitUpdate();
                break;

            // sign in user
            case ActionTypes.REQUEST_SIGN_IN_USER_SUCCESS:
                waitFor([AuthStore.dispatchToken]);
                userIdentityAffiliateStatus = UserIdentityStatusType.getValue(action.userIdentityDTO && action.userIdentityDTO.AffiliateStatus, 'statusCodeAffiliate');
                if (userIdentityAffiliateStatus === UserIdentityStatusType.STATUS_ACTIVE
                    || userIdentityAffiliateStatus === UserIdentityStatusType.STATUS_UNKNOWN) {
                    this._notification = StandardNotifications.SIGN_IN_USER_SUCCESS();
                } else if (userIdentityAffiliateStatus === UserIdentityStatusType.STATUS_CLOSED) {
                    this._notification = StandardNotifications.SIGN_IN_USER_ERROR(
                        new APIRequestError("account_closed", null, {email: action.username, sub: action.username})
                    );
                } else {
                    this._notification = StandardNotifications.SIGN_IN_USER_ERROR(
                        new APIRequestError("account_inactive_or_suspended", null, {email: action.username})
                    );
                }

                // no op
                // this._notification = StandardNotifications.SIGN_IN_USER_ERROR(
                //     new APIRequestError("not_activated", null, {email: action.username})
                // );

                this.emitUpdate();
                break;

            case ActionTypes.REQUEST_SIGN_IN_USER_ERROR:
                waitFor([AuthStore.dispatchToken]);
                this._notification = StandardNotifications.SIGN_IN_USER_ERROR(action.error);
                this.emitUpdate();
                break;

            // change user password
            // reset user password
            // update user profile and password (error)
            case ActionTypes.REQUEST_CHANGE_USER_PASSWORD_SUCCESS:
            case ActionTypes.REQUEST_RESET_USER_PASSWORD_SUCCESS:
                waitFor([AuthStore.dispatchToken]);
                this._notification = StandardNotifications.CHANGE_USER_PASSWORD_SUCCESS();
                this.emitUpdate();
                break;

            case ActionTypes.REQUEST_CHANGE_USER_PASSWORD_ERROR:
            case ActionTypes.REQUEST_RESET_USER_PASSWORD_ERROR:
            case ActionTypes.REQUEST_UPDATE_USER_PROFILE_AND_PASSWORD_ERROR:
                waitFor([AuthStore.dispatchToken]);
                this._notification = StandardNotifications.CHANGE_USER_PASSWORD_ERROR(action.error);
                this.emitUpdate();
                break;

            // update user profile
            // update user profile and password (success)
            case ActionTypes.REQUEST_UPDATE_USER_PROFILE_SUCCESS:
            case ActionTypes.REQUEST_UPDATE_USER_PROFILE_AND_PASSWORD_SUCCESS:
                waitFor([AuthStore.dispatchToken]);
                this._notification = StandardNotifications.UPDATE_USER_PROFILE_SUCCESS();
                this.emitUpdate();
                break;

            case ActionTypes.REQUEST_UPDATE_USER_PROFILE_ERROR:
                waitFor([AuthStore.dispatchToken]);
                this._notification = StandardNotifications.UPDATE_USER_PROFILE_ERROR(action.error);
                this.emitUpdate();
                break;

            // update user preferences (success)
            case ActionTypes.REQUEST_UPDATE_USER_PREFERENCES_SUCCESS:
                waitFor([AuthStore.dispatchToken]);
                this._notification = StandardNotifications.UPDATE_USER_PREFERENCES_SUCCESS();
                this.emitUpdate();
                break;

            case ActionTypes.REQUEST_UPDATE_USER_PREFERENCES_ERROR:
                waitFor([AuthStore.dispatchToken]);
                this._notification = StandardNotifications.UPDATE_USER_PREFERENCES_ERROR(action.error);
                this.emitUpdate();
                break;

            // sign out user
            case ActionTypes.SIGN_OUT_USER:
                waitFor([AuthStore.dispatchToken]);
                this._notification = StandardNotifications.SIGN_OUT_USER();
                this.emitUpdate();
                break;
           // timeout user
            case ActionTypes.REQUEST_SESSION_TIMEOUT:
                 waitFor([AuthStore.dispatchToken]);
                 this._notification = StandardNotifications.REQUEST_SESSION_TIMEOUT();
                 this.emitUpdate();
                 break;
            // send reset user password email
            case ActionTypes.REQUEST_SEND_RESET_USER_PASSWORD_EMAIL_SUCCESS:
                waitFor([AuthStore.dispatchToken]);
                this._notification = StandardNotifications.SEND_RESET_USER_PASSWORD_EMAIL_SUCCESS();
                this.emitUpdate();
                break;

            case ActionTypes.REQUEST_SEND_RESET_USER_PASSWORD_EMAIL_ERROR:
                waitFor([AuthStore.dispatchToken]);
                this._notification = StandardNotifications.SEND_RESET_USER_PASSWORD_EMAIL_ERROR(action.error);
                this.emitUpdate();
                break;

            // add payment method to user account
            // update payment method to user account
            case ActionTypes.REQUEST_ADD_PAYMENT_METHOD_SUCCESS:
            case ActionTypes.REQUEST_UPDATE_PAYMENT_METHOD_SUCCESS:
                waitFor([AuthStore.dispatchToken]);
                if (!action.preventNotifications) {
                    this._notification = StandardNotifications.SET_PAYMENT_METHOD_SUCCESS();
                }
                this.emitUpdate();
                break;

            case ActionTypes.REQUEST_ADD_PAYMENT_METHOD_ERROR:
            case ActionTypes.REQUEST_UPDATE_PAYMENT_METHOD_ERROR:
                waitFor([AuthStore.dispatchToken]);
                if (!action.preventNotifications) {
                    this._overlayNotification = StandardNotifications.SET_PAYMENT_METHOD_ERROR(action.error);
                }
                this.emitUpdate();
                break;


            // add/remove media item from watchlist
            case ActionTypes.REQUEST_ADD_MEDIA_ITEM_TO_WATCHLIST_ERROR:
            case ActionTypes.REQUEST_REMOVE_MEDIA_ITEM_FROM_WATCHLIST_ERROR:
                waitFor([WatchlistStore.dispatchToken]);
                this._notification = StandardNotifications.ADD_REMOVE_MEDIA_ITEM_FROM_WATCHLIST_ERROR(action.error);
                this.emitUpdate();
                break;

            // on fast checkout media item offer error
            // on fast checkout bundle offer error
            case ActionTypes.REQUEST_FAST_CHECKOUT_MEDIA_ITEM_OFFER_ERROR:
            case ActionTypes.REQUEST_FAST_CHECKOUT_BUNDLE_OFFER_ERROR:
            case ActionTypes.REQUEST_GET_BUNDLE_SHOPPING_CART_VALUE_ERROR:
                waitFor([PurchaseStore.dispatchToken]);
                this._notification = StandardNotifications.CHECKOUT_MEDIA_ITEM_OFFER_ERROR(action.error);
                this.emitUpdate();
                break;

            // remove registered device
            case ActionTypes.REQUEST_REMOVE_REGISTERED_DEVICE_ERROR:
                waitFor([EntitlementStore.dispatchToken]);
                this._notification = StandardNotifications.REMOVE_REGISTERED_DEVICE_ERROR(action.error);
                this.emitUpdate();
                break;

            // remove registered device
            case ActionTypes.REQUEST_CHANGE_REGISTERED_DEVICE_NAME_ERROR:
                waitFor([EntitlementStore.dispatchToken]);
                this._notification = StandardNotifications.RENAME_REGISTERED_DEVICE_ERROR(action.error);
                this.emitUpdate();
                break;

            // vubi charge basket error
            case ActionTypes.REQUEST_CHARGE_BASKET_ERROR:
                waitFor([EntitlementStore.dispatchToken]);
                console.log(action);
                this._overlayNotification = StandardNotifications.CHARGE_BASKET_ERROR(action.error);
                this.emitUpdate();
                break;

            default:
                break;
        }
    }

    /**
     * Get _notification
     *
     * @returns {null|*}
     */
    get notification() {
        return this._notification;
    }

    /**
     * get stripe notification
     */
    get overlayNotification() {
        return this._overlayNotification;
    }

    /**
     * Get _error
     *
     * @returns {null|*}
     */
    get error() {
        return this._error;
    }
}

export default new NotificationStore();
