"use strict";

import Immutable from 'immutable';
import moment from 'moment';
import store from 'store';
import RegisteredDevice from '../types/RegisteredDevice';
import Entitlement from '../types/Entitlement';
import AMSLicense from '../types/AMSLicense';
import RecentlyViewedItem from '../types/RecentlyViewedItem';
import ActionTypes from '../actions/ActionTypes';
import BaseStore from './BaseStore';
import AuthStore from './AuthStore';
import {waitFor} from '../dispatchers/AppDispatcher';

/**
 * Flux: EntitlementStore
 */
class EntitlementStore extends BaseStore {
    /**
     * Current entitlements
     *
     * @type {Immutable.Map<string, Entitlement>} _currentEntitlements
     * @type {Immutable.Map<string, Entitlement>} _currentEntitlementsByMediaItem
     * @private
     */
    _currentEntitlements = Immutable.Map();
    _currentEntitlementsByMediaItem = Immutable.Map();

    /**
     * Recently viewed items
     *
     * @type {Immutable.Map<string, RecentlyViewedItem>} _recentlyViewedItems
     * @private
     */
    _recentlyViewedItems = Immutable.Map();

    /**
     * Player data
     */
    _playerMediaItem = null;
    _playerAction = null;
    _playerAMSLicenses = Immutable.Map();
    _playerTrailerLicenses = Immutable.Map();

    /**
     * Playback log (logwatch response)
     */
    _playbackLog = null;

    /**
     * Registered devices
     *
     * type {Immutable.OrderedMap<string, RegisteredDevice>} _registeredDevices
     * @private
     */
    _registeredDevices = Immutable.OrderedMap();
    _newDeviceRegisteredSuccess = false;

    /**
     * Vision360 api response data
     */
    _stbPairingProcessStarted = false;
    _stbPairingProcessSuccess = false;
    _stbPairingFromProductPurchase = false;
    _stbPairingProcessErrorMessage = "";
    _stbPairingData = Immutable.Map();

    _trailer = false;
    /**
     * Constructor
     */
    constructor() {
        super();
        this.subscribe(() => this._registerToActions.bind(this));
    }

    /**
     * Flux: register store to actions
     *
     * @param action
     * @private
     */
    _registerToActions(action) {
        switch (action.type) {
            // get current entitlements
            case ActionTypes.REQUEST_GET_CURRENT_ENTITLEMENTS:
                this._error = null;
                break;

            case ActionTypes.REQUEST_GET_CURRENT_ENTITLEMENTS_SUCCESS:
                this._setCurrentEntitlements(action.entitlementDTOCollection);
                this._error = null;
                this.emitUpdate();
                break;

            case ActionTypes.REQUEST_GET_RECENTLY_VIEWED_MEDIA_SUCCESS: // here latest entitlements are also loaded
                this._setCurrentEntitlements(action.entitlementDTOCollection);
                this._setRecentlyViewedItems(action.recentlyViewedItemDTOCollection);
                this._error = null;
                this.emitUpdate();
                break;

            case ActionTypes.REQUEST_GET_CURRENT_ENTITLEMENTS_ERROR:
                this._error = action.error;
                this.emitUpdate();
                break;

            // get recently viewed items
            case ActionTypes.REQUEST_GET_RECENTLY_VIEWED_ITEMS:
                this._error = null;
                break;

            case ActionTypes.REQUEST_GET_RECENTLY_VIEWED_ITEMS_SUCCESS:
                this._setRecentlyViewedItems(action.recentlyViewedItemDTOCollection);
                this._error = null;
                this.emitUpdate();
                break;

            case ActionTypes.REQUEST_GET_RECENTLY_VIEWED_ITEMS_ERROR:
                this._error = action.error;
                this.emitUpdate();
                break;

            // get AMS license
            case ActionTypes.REQUEST_GET_AMS_LICENSE:
                this._error = null;
                break;

            case ActionTypes.REQUEST_GET_AMS_LICENSE_SUCCESS:
                this._playerAMSLicenses = this._playerAMSLicenses.set(action.mediaItemId, new AMSLicense({}).fromDTO(action.amsLicenceDTO));
                this._error = null;
                this.emitUpdate();
                break;

            case ActionTypes.REQUEST_GET_AMS_LICENSE_ERROR:
                this._error = action.error;
                this.emitUpdate();
                break;

                  // get Trailer license
            case ActionTypes.REQUEST_GET_TRAILER_LICENSE:
                this._error = null;
                break;

            case ActionTypes.REQUEST_GET_TRAILER_LICENSE_SUCCESS:
                this._playerTrailerLicenses = this._playerTrailerLicenses.set(action.mediaItemId, new AMSLicense({}).fromDTO(action.amsLicenceDTO));
                this._error = null;
                this.emitUpdate();
                break;

            case ActionTypes.REQUEST_GET_TRAILER_LICENSE_ERROR:
                this._error = action.error;
                this._trailer = false;
                this.emitUpdate();
                break;


            // media item playback/download
            case ActionTypes.START_MEDIA_ITEM_PLAYBACK:
                this._playerMediaItem = action.mediaItem;
                this._playerAction = action.playerAction;
                this.emitUpdate();
                break;

            case ActionTypes.STOP_MEDIA_ITEM_PLAYBACK:
                if (this._playerMediaItem && this._playerAction === 'play') {
                    this._addRecentlyViewedItem(this._playerMediaItem.id);
                }
                this._playerMediaItem = null;
                this._playerAction = null;
                this.emitUpdate();
                break;

            // create playback log
            case ActionTypes.REQUEST_CREATE_PLAYBACK_LOG:
                this._error = null;
                break;

            case ActionTypes.REQUEST_CREATE_PLAYBACK_LOG_SUCCESS:
                this._updateCurrentEntitlementsTimeStamp(action.mediaItemId, action.currentTime);
                this.emitUpdate();
                break;

            case ActionTypes.REQUEST_CREATE_PLAYBACK_LOG_ERROR:
                this._error = action.error;
                this.emitUpdate();
                break;

            // Register new device
            case ActionTypes.REQUEST_REGISTER_DEVICE:
                // console.log('Entitlement Store - register new device');
                this._error = null;
                break;

            case ActionTypes.REQUEST_REGISTER_DEVICE_SUCCESS:
                // console.log('Entitlement Store - register new device success');
                this._newDeviceRegisteredSuccess = true;
                this._error = null;
                this.emitUpdate();
                break;

            case ActionTypes.REQUEST_REGISTER_DEVICE_ERROR:
                // console.log('Entitlement Store - register new device error');
                console.log(action.error);
                this._error = action.error;
                this.emitUpdate();
                break;

            // Get registered devices
            case ActionTypes.REQUEST_GET_REGISTERED_DEVICES:
                this._registeredDevices = null;
                this._error = null;
                break;

            case ActionTypes.REQUEST_GET_REGISTERED_DEVICES_SUCCESS:
                this._setRegisteredDevices(action.registeredDeviceDTOCollection);
                this._error = null;
                this.emitUpdate();
                break;

            case ActionTypes.REQUEST_GET_REGISTERED_DEVICES_ERROR:
                this._error = action.error;
                this.emitUpdate();
                break;

            // Rename registered device
            case ActionTypes.REQUEST_CHANGE_REGISTERED_DEVICE_NAME:
                //this._registeredDevices = this._registeredDevices.set(action.deviceId);
                this._error = null;
                this.emitUpdate();
                break;

            case ActionTypes.REQUEST_CHANGE_REGISTERED_DEVICE_NAME_SUCCESS:
                this._renameRegisteredDevice(action.registeredDevice, action.newName);
                this._error = null;
                this.emitUpdate();
                break;

            case ActionTypes.REQUEST_CHANGE_REGISTERED_DEVICE_NAME_ERROR:
                //this._registeredDevices = this._registeredDevices.set(action.deviceId, this._registeredDevices.get(action.deviceId).setUpdatePending(false));
                this._error = action.error;
                this.emitUpdate();
                break;

            // Remove registered device
            case ActionTypes.REQUEST_REMOVE_REGISTERED_DEVICE:
                this._registeredDevices = this._registeredDevices.set(action.deviceId, this._registeredDevices.get(action.deviceId).setUpdatePending(true));
                this._error = null;
                this.emitUpdate();
                break;

            case ActionTypes.REQUEST_REMOVE_REGISTERED_DEVICE_SUCCESS:
                this._registeredDevices = this._registeredDevices.delete(action.deviceId);
                this._error = null;
                this.emitUpdate();
                break;

            case ActionTypes.REQUEST_REMOVE_REGISTERED_DEVICE_ERROR:
                this._registeredDevices = this._registeredDevices.set(action.deviceId, this._registeredDevices.get(action.deviceId).setUpdatePending(false));
                this._error = action.error;
                this.emitUpdate();
                break;

            // on get library media
            case ActionTypes.REQUEST_GET_LIBRARY_MEDIA_SUCCESS:
                this._setCurrentEntitlements(action.entitlementDTOCollection);
                this._error = null;
                this.emitUpdate();
                break;

            // on charge basket success
            case ActionTypes.REQUEST_CHARGE_BASKET_SUCCESS:
                this._setCurrentEntitlements(action.entitlementDTOCollection);
                this.emitUpdate();
                break;

            // on create purchase order vod item success
            case ActionTypes.REQUEST_CREATE_PURCHASE_ORDER_VOD_ITEM_SUCCESS:
                this._setCurrentEntitlements(action.entitlementDTOCollection);
                this.emitUpdate();
                break;

            // enable the stb pairing process
            case ActionTypes.REQUEST_STB_PAIRING_PROCESS_ENABLED:
                this._stbPairingProcessStarted = true;
                this._error = null;
                this.emitUpdate();
                break;

            // disable stb pairing process
            case ActionTypes.REQUEST_STB_PAIRING_PROCESS_DISABLED:
                this._stbPairingProcessStarted = false;
                this._error = null;
                this.emitUpdate();
                break;

            // finish stb pairing process
            case ActionTypes.REQUEST_STB_PAIRING_PROCESS_FINISHED:
                this._stbPairingData = Immutable.Map();
                this._newDeviceRegisteredSuccess = false;
                this._stbPairingProcessSuccess = false;
                this._error = null;
                this.emitUpdate();
                break;

            // on validate stb link code
            case ActionTypes.REQUEST_VALIDATE_STB_CODE:
                this._stbPairingData = Immutable.Map();
                this._error = null;
                this.emitUpdate();
                break;

            case ActionTypes.REQUEST_VALIDATE_STB_CODE_SUCCESS:
                this._error = null;
                this._stbPairingData = this._stbPairingData.set('validated', action.validated);
                this._stbPairingData = this._stbPairingData.set('fromProductPurchase', action.fromProductPurchase);
                this._stbPairingData = this._stbPairingData.set('stbCode', action.stbCode);
                this._stbPairingFromProductPurchase = action.fromProductPurchase;
                this.emitUpdate();
                break;

            case ActionTypes.REQUEST_VALIDATE_STB_CODE_ERROR:
                this._stbPairingData = this._stbPairingData.set('error', action.error);
                this._error = action.error;
                this.emitUpdate();
                break;

            // on consume stb link code
            case ActionTypes.REQUEST_CONSUME_STB_CODE:
                this._error = null;
                this.emitUpdate();
                break;

            case ActionTypes.REQUEST_CONSUME_STB_CODE_SUCCESS:
                this._error = null;
                this._stbPairingData = this._stbPairingData.set('consumed', action.consumed);
                this._stbPairingData = this._stbPairingData.set('deviceFriendlyName', action.deviceFriendlyName);
                this._stbPairingData = this._stbPairingData.set('deviceID', action.deviceID);
                this.emitUpdate();
                break;

            case ActionTypes.REQUEST_CONSUME_STB_CODE_ERROR:
                this._stbPairingData = this._stbPairingData.set('error', action.error);
                this._error = action.error;
                this.emitUpdate();
                break;

            // on confirm stb registration
            case ActionTypes.REQUEST_CONFIRM_STB_REGISTRATION:
                waitFor([AuthStore.dispatchToken]);
                this._error = null;
                this.emitUpdate();
                break;

            case ActionTypes.REQUEST_CONFIRM_STB_REGISTRATION_SUCCESS:
                this._stbPairingData = Immutable.Map();
                this._stbPairingProcessSuccess = true;
                this._error = null;
                this.emitUpdate();
                break;

            case ActionTypes.REQUEST_CONFIRM_STB_REGISTRATION_ERROR:
                console.log(action.error);
                this._stbPairingData = this._stbPairingData.set('error', action.error);
                this._stbPairingProcessErrorMessage = action.error;
                this._error = action.error;
                this.emitUpdate();
                break;

            // waited for by AuthStore: sign out user
            case ActionTypes.SIGN_OUT_USER:
                case ActionTypes.REQUEST_SESSION_TIMEOUT:
                // clear important maps
                this._currentEntitlements = Immutable.Map();
                this._currentEntitlementsByMediaItem = Immutable.Map();
                this._playerAMSLicenses = Immutable.Map();
                this._recentlyViewedItems = Immutable.Map();
                this._registeredDevices = Immutable.OrderedMap();
                this._stbPairingData = Immutable.Map();
                this._stbPairingProcessStarted = false;
                this._stbPairingProcessSuccess = false;
                break;

            default:
                break;
        }
    }

    /**
     * Set _currentEntitlements
     *
     * @param {Array<EntitlementDTO>} entitlementDTOCollection
     * @private
     */
    _setCurrentEntitlements(entitlementDTOCollection) {
        /** @type {EntitlementDTO} entitlementDTO */
        entitlementDTOCollection.forEach((entitlementDTO) => {
            let entitlement = new Entitlement({}).fromDTO(entitlementDTO);
            if (entitlement.id) {
                this._currentEntitlements = this._currentEntitlements.set(entitlement.id, entitlement);
                if (entitlement.mediaItemId) {
                    this._currentEntitlementsByMediaItem = this._currentEntitlementsByMediaItem.set(entitlement.mediaItemId, entitlement);
                }
            }
        });
    }

    /**
     * Update _currentEntitlements lastPlayPosition and watchedPercentage attributes
     *
     * @param {string} mediaItemId
     * @param {string} lastPlayPosition
     * @private
     */
    _updateCurrentEntitlementsTimeStamp(mediaItemId, lastPlayPosition) {
        let entitlement = this._currentEntitlementsByMediaItem.get(mediaItemId);
        if (!entitlement) return;

        entitlement = entitlement.setLastPlayPosition(lastPlayPosition);
        this._currentEntitlementsByMediaItem = this._currentEntitlementsByMediaItem.set(entitlement.mediaItemId, entitlement);
        if (this._currentEntitlements.get(entitlement.id)) {
            this._currentEntitlements = this._currentEntitlements.set(entitlement.id, entitlement);
        }
    }

    /**
     * Get _currentEntitlements
     *
     * @returns {Immutable.Map<string, Entitlement>}
     */
    get currentEntitlements() {
        return this._currentEntitlements;
    }

    /**
     * Get _currentEntitlementsByMediaItem
     *
     * @returns {Immutable.Map<string, Entitlement>}
     */
    get currentEntitlementsByMediaItem() {
        return this._currentEntitlementsByMediaItem;
    }

    /**
     * Set _recentlyViewedItems
     *
     * @param {Array<RecentlyViewedItemDTO>} recentlyViewedItemDTOCollection
     * @private
     */
    _setRecentlyViewedItems(recentlyViewedItemDTOCollection) {
        this._recentlyViewedItems = Immutable.Map();

        /** @type {RecentlyViewedItemDTO} recentlyViewedItemDTO */
        recentlyViewedItemDTOCollection.forEach((recentlyViewedItemDTO) => {
            let recentlyViewedItem = new RecentlyViewedItem({}).fromDTO(recentlyViewedItemDTO);
            if (recentlyViewedItem.mediaItemId) {
                this._recentlyViewedItems = this._recentlyViewedItems.set(recentlyViewedItem.mediaItemId, recentlyViewedItem);
            }
        });
    }

    /**
     * Add item to _recentlyViewedItems
     *
     * @param {string} mediaItemId
     * @private
     */
    _addRecentlyViewedItem(mediaItemId) {
        if (!this._recentlyViewedItems.get(mediaItemId)) {
            let entitlement = this._currentEntitlementsByMediaItem.get(mediaItemId);
            let recentlyViewedItem = new RecentlyViewedItem({
                mediaItemId: mediaItemId,
                lastViewed: moment(),
                lastPlayPosition: entitlement ? entitlement.lastPlayPosition : '00:00:00'
            });
            this._recentlyViewedItems = this._recentlyViewedItems.set(recentlyViewedItem.mediaItemId, recentlyViewedItem);
        }
    }

    /**
     * Get _recentlyViewedItems
     *
     * @returns {Immutable.Map<string, RecentlyViewedItem>}
     */
    get recentlyViewedItems() {
        return this._recentlyViewedItems;
    }

    /**
     * Get _playerMediaItem
     *
     * @returns {*}
     */
    get playerMediaItem() {
        return this._playerMediaItem;
    }

    /**
     * Get _playerAction
     *
     * @returns {*}
     */
    get playerAction() {
        return this._playerAction;
    }

    /**
     * Get _playerAMSLicenses
     *
     * @returns {Immutable.Map<string, AMSLicense>}
     */
    get playerAMSLicenses() {
        return this._playerAMSLicenses;
    }

    /**
     * Get _playerTrailerLicense
     *
     * @returns {Immutable.Map<string, AMSLicense>}
     */
    get playerTrailerLicense() {
        return this._playerTrailerLicenses;
    }

    /**
     * Get stbPairingData
     *
     * @returns {Immutable.Map}
     */
    get stbPairingData() {
        return this._stbPairingData;
    }

    /**
     * Remove playerAMSLicense (anti-pattern: called directly on store)
     */
    removePlayerAMSLicense(mediaItemId) {
        this._playerAMSLicenses = this._playerAMSLicenses.delete(mediaItemId);
        return null;
    }

    /**
     * Get _playbackLog
     *
     * @returns {LogWatchResponse}
     */
    get playbackLog() {
        return this._playbackLog;
    }

    /**
     * Get new device registered successfully
     */
    get newDeviceRegisteredSuccess() {
        return this._newDeviceRegisteredSuccess;
    }

    /**
     * get stb device registration status message
     */
    get stbDeviceRegistrationStatus() {
        return {
            stbPairingProcessSuccess: this._stbPairingProcessSuccess,
            error: this._stbPairingProcessErrorMessage,
            fromProductPurchase: this._stbPairingFromProductPurchase
        };
    }

    /**
     * Set _registeredDevices
     *
     * @param {Array<RegisteredDeviceDTO>} registeredDeviceDTOCollection
     * @private
     */
    _setRegisteredDevices(registeredDeviceDTOCollection) {
        this._registeredDevices = Immutable.OrderedMap();

        /** @type {RegisteredDeviceDTO} registeredDeviceDTO */
        registeredDeviceDTOCollection.forEach((registeredDeviceDTO) => {
            let registeredDevice = new RegisteredDevice({}).fromDTO(registeredDeviceDTO);
            if (registeredDevice.deviceId) {
                this._registeredDevices = this._registeredDevices.set(registeredDevice.deviceId, registeredDevice);
            }
        });
    }

    /**
     * Set _renameRegisteredDevice
     *
     * @param {Object} renamedDevice
     * @param {String} newName
     * @private
     */
    _renameRegisteredDevice(renamedDevice, newName) {
        //this._registeredDevices = Immutable.OrderedMap();
        const tempDevice = renamedDevice.set('deviceName', newName);
        this._registeredDevices = this.registeredDevices.set(tempDevice.get('deviceId'), tempDevice);
    }

    /**
     * Get _registeredDevices
     *
     * @returns {Immutable.OrderedMap<string, Entitlement>}
     */
    get registeredDevices() {
        return this._registeredDevices;
    }

    /**
     * check if _stbPairingProcess has started
     * @returns {Boolean}
     */
    get stbPairingProcessStarted() {
        return this._stbPairingProcessStarted;
    }


    get SetTrailerStatus() {
       this._trailer = true;
       return this._trailer;
    }

    get DisableTrailerStatus() {
        this._trailer = false;
        return this._trailer;
     }

     get currentTrailerStatus() {
        return this._trailer;
     }
    /**
     * Get _error
     *
     * @returns {null|*}
     */
    get error() {
        return this._error;
    }
}

export default new EntitlementStore();
