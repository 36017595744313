"use strict";

import store from 'store';
import Immutable from 'immutable';
import uuidGenerator from 'uuid';
import {waitFor} from '../dispatchers/AppDispatcher';
import Config from '../lib/Config';
import LangEN from '../lib/translations/en/Lang';
import Genre from '../types/Genre';
import Collection from '../types/Collection';
import PageRow, {PageRowType} from '../types/PageRow';
import MainNavigationItem from '../types/MainNavigationItem';
import MainNavigationSection from '../types/MainNavigationSection';
import Certificate from '../types/Certificate';
import AddressLookupResult from '../types/AddressLookupResult';
import Filter from '../lib/Filter';
import BaseStore from './BaseStore';
import PlatformArticle from '../types/PlatformArticle';
import AppSetting from '../types/AppSetting';
import Promotion from '../types/Promotion';
import ActionTypes from '../actions/ActionTypes';
import AuthStore from '../stores/AuthStore';
import WatchlistStore from '../stores/WatchlistStore';
import EntitlementStore from '../stores/EntitlementStore';
import Router from '../lib/Router';

/**
 * Flux: AppStore
 */
class AppStore extends BaseStore {
    /**
     * Content pre-loading flags
     *
     * @type {boolean} _deviceIdFingerprintingCompleted
     * @type {boolean} _appSettingsLoadingCompleted
     * @type {boolean} _translationsLoadingCompleted
     * @type {boolean} _platformArticlesLoadingCompleted
     * @type {boolean} _certificateListLoadingCompleted
     * @type {boolean} _userProfileLoadingCompleted
     * @type {boolean} _recentlyViewedItemsLoadingCompleted
     * @type {boolean} _watchlistLoadingCompleted
     * @type {boolean} _currentEntitlementsLoadingCompleted
     * @type {Array} _emptyCollectionList
     * @private
     */
    _deviceIdFingerprintingCompleted = false;
    _appSettingsLoadingCompleted = false;
    _translationsLoadingCompleted = false;
    _platformArticlesLoadingCompleted = false;
    _certificateListLoadingCompleted = false;
    _userProfileLoadingCompleted = false;
    _recentlyViewedItemsLoadingCompleted = false;
    _watchlistLoadingCompleted = false;
    _currentEntitlementsLoadingCompleted = false;
    _emptyCollectionList = null;

    /**
     * Device type and id
     *
     * @type {?string}
     * @private
     */
    _deviceType = '';
    _deviceId = '';

    /**
     * Google analytics CID
     *
     * @type {?string}
     * @private
     */
    _gaCID = null;

    /**
     * _appSettings
     *
     * @type {Immutable.OrderedMap<string, AppSetting>}
     * @private
     */
    _appSettings = Immutable.OrderedMap();

    /**
     * _collectionList
     *
     * @type {Immutable.OrderedMap<string, Collection>}
     * @private
     */
    _collectionList = Immutable.OrderedMap();
    _collectionListByTitle = Immutable.OrderedMap();

    /**
     * _promotionList
     *
     * @type {Immutable.OrderedMap<string, Promotion>}
     * @private
     */
    _promotionList = Immutable.OrderedMap();
    _promotionListByName = Immutable.OrderedMap();

    /**
     * _mainNavigation
     *
     * @type {Immutable.OrderedMap<string, MainNavigationSection>}
     * @private
     */
    _mainNavigation = Immutable.OrderedMap();

    /**
     * navigation search focus
     * @type {boolean}
     */
    _navigationSearchFocused = false;

    /**
     * _translations
     *
     * @type {Object}
     * @private
     */
    _translations = {
        en: LangEN
    };

    /**
     * Genre list
     *
     * @type {Immutable.OrderedMap<string, Genre>|null} _genreList
     * @private
     */
    _genreList = Immutable.OrderedMap();

    /**
     * Certificates list
     *
     * @type {Immutable.OrderedMap<string, Certificate>|null} _certificateList
     * @private
     */
    _certificateList = Immutable.OrderedMap();

    /**
     * _platformArticles
     *
     * @type {Immutable.Map<string, PlatformArticle>}
     * @private
     */
    _platformArticles = Immutable.Map();

    /**
     * _transitionPaths
     *
     * @type {Array<string>}
     * @private
     */
    _transitionPaths = [];

    /**
     * _activeFilters
     *
     * @type {Immutable.OrderedMap<string, Object>}
     * @private
     */
    _activeFilters = Immutable.OrderedMap();

    /**
     * Address lookup by postcode results
     */
    _addressLookupResult = null;
    _addressLookupPending = null;

    /**
     * Constructor
     */
    constructor() {
        super();
        this.subscribe(() => this._registerToActions.bind(this));

        this._getFiltersFromLocalStorage();
    }

    /**
     * Flux: register store to actions
     *
     * @param action
     * @private
     */
    _registerToActions(action) {
        this._error = null;

        switch (action.type) {
            // initialize session: device type
            case ActionTypes.STORE_DEVICE_TYPE:
                this._deviceType = action.deviceType;
                store.set("device_type", this._deviceType);
                this._gaCID = store.get("ga_cid") || uuidGenerator.v4();
                store.set("ga_cid", this._gaCID);
                this._error = null;
                this.emitUpdate();
                break;

            // initialize session: device and session id
            case ActionTypes.STORE_FINGERPRINT_DEVICE_ID:
                this._deviceIdFingerprintingCompleted = true;
                this._deviceId = action.deviceId;
                this._sessionId = action.uuid;
                store.set("device_id", this._deviceId);
                store.set("session_id", this._sessionId);
                this._error = null;
                this.emitUpdate();
                break;

            // pre-load: app settings
            case ActionTypes.REQUEST_GET_APP_SETTINGS:
                this._appSettingsLoadingCompleted = false;
                this._error = null;
                this.emitUpdate();
                break;

            case ActionTypes.REQUEST_GET_APP_SETTINGS_SUCCESS:
                this._appSettingsLoadingCompleted = true;
                this._setGenreList(action.genreDTOCollection);
                this._setCollectionList(action.collectionDTOCollection);
                this._setPromotionList(action.promotionDTOCollection);
                this._setAppSettings(action.appSettingDTOCollection);
                this._setMainNavigation();
                this._getFiltersFromLocalStorage();
                this._error = null;
                this.emitUpdate();
                break;

            case ActionTypes.REQUEST_GET_APP_SETTINGS_ERROR:
                this._appSettingsLoadingCompleted = true;
                this._getFiltersFromLocalStorage();
                this._error = action.error;
                this.emitUpdate();
                break;

            // pre-load: translations
            case ActionTypes.REQUEST_GET_TRANSLATIONS:
                this._translationsLoadingCompleted = false;
                this._error = null;
                this.emitUpdate();
                break;

            case ActionTypes.REQUEST_GET_TRANSLATIONS_SUCCESS:
                this._setTranslations(action.translations);
                this._translationsLoadingCompleted = true;
                this.emitUpdate();
                break;

            case ActionTypes.REQUEST_GET_TRANSLATIONS_ERROR:
                this._translationsLoadingCompleted = true;
                this.emitUpdate();
                break;

            // pre-load: certificates list
            case ActionTypes.REQUEST_GET_CERTIFICATE_LIST:
                this._certificateListLoadingCompleted = false;
                this._error = null;
                this.emitUpdate();
                break;

            case ActionTypes.REQUEST_GET_CERTIFICATE_LIST_SUCCESS:
                this._setCertificateList(action.certificateDTOCollection);
                this._certificateListLoadingCompleted = true;
                this._getFiltersFromLocalStorage();
                this._error = null;
                this.emitUpdate();
                break;

            case ActionTypes.REQUEST_GET_CERTIFICATE_LIST_ERROR:
                this._certificateListLoadingCompleted = true;
                this._getFiltersFromLocalStorage();
                this._error = action.error;
                this.emitUpdate();
                break;

            // pre-load: platform articles
            case ActionTypes.REQUEST_GET_PLATFORM_ARTICLES:
                this._platformArticlesLoadingCompleted = false;
                this._error = null;
                this.emitUpdate();
                break;

            case ActionTypes.REQUEST_GET_PLATFORM_ARTICLES_SUCCESS:
                this._setPlatformArticles(action.platformArticleDTOCollection);
                this._platformArticlesLoadingCompleted = true;
                this.emitUpdate();
                break;

            case ActionTypes.REQUEST_GET_PLATFORM_ARTICLES_ERROR:
                this._platformArticlesLoadingCompleted = true;
                this.emitUpdate();
                break;

            // pre-load: user profile
            case ActionTypes.REQUEST_GET_USER_PROFILE:
                waitFor([AuthStore.dispatchToken]);
                this._userProfileLoadingCompleted = false;
                this._error = null;
                this.emitUpdate();
                break;

            case ActionTypes.REQUEST_GET_USER_PROFILE_SUCCESS:
            case ActionTypes.REQUEST_GET_USER_PROFILE_ERROR:
                waitFor([AuthStore.dispatchToken]);
                this._userProfileLoadingCompleted = true;
                this.emitUpdate();
                break;

            // pre-load: recently viewed items
            case ActionTypes.REQUEST_GET_RECENTLY_VIEWED_ITEMS:
                waitFor([EntitlementStore.dispatchToken]);
                this._recentlyViewedItemsLoadingCompleted = false;
                this._error = null;
                this.emitUpdate();
                break;

            case ActionTypes.REQUEST_GET_RECENTLY_VIEWED_ITEMS_SUCCESS:
            case ActionTypes.REQUEST_GET_RECENTLY_VIEWED_ITEMS_ERROR:
                waitFor([EntitlementStore.dispatchToken]);
                this._recentlyViewedItemsLoadingCompleted = true;
                this.emitUpdate();
                break;

            // pre-load: watchlist
            case ActionTypes.REQUEST_GET_WATCHLIST:
                waitFor([WatchlistStore.dispatchToken]);
                this._watchlistLoadingCompleted = false;
                this._error = null;
                this.emitUpdate();
                break;

            case ActionTypes.REQUEST_GET_WATCHLIST_SUCCESS:
            case ActionTypes.REQUEST_GET_WATCHLIST_ERROR:
                waitFor([WatchlistStore.dispatchToken]);
                this._watchlistLoadingCompleted = true;
                this.emitUpdate();
                break;

            // pre-load: current entitlements
            case ActionTypes.REQUEST_GET_CURRENT_ENTITLEMENTS:
                waitFor([EntitlementStore.dispatchToken]);
                this._currentEntitlementsLoadingCompleted = false;
                this._error = null;
                this.emitUpdate();
                break;

            case ActionTypes.REQUEST_GET_CURRENT_ENTITLEMENTS_SUCCESS:
            case ActionTypes.REQUEST_GET_CURRENT_ENTITLEMENTS_ERROR:
                waitFor([EntitlementStore.dispatchToken]);
                this._currentEntitlementsLoadingCompleted = true;
                this.emitUpdate();
                break;

            // router
            case ActionTypes.STORE_ROUTER_TRANSITION_PATH:
                this._transitionPaths.push(action.path);
                break;

            // filters
            case ActionTypes.TOGGLE_FILTER:
                this._updateActiveFilters(action.filterKey);
                this.emitUpdate();
                break;

            // navigation focused
            case ActionTypes.TOGGLE_NAVIGATION_FOCUS:
                this._navigationSearchFocused = action.isFocused;
                this.emitUpdate();
                break;

            case ActionTypes.RESET_FILTERS:
                this._resetFilters();
                this.emitUpdate();
                break;

            case ActionTypes.LOAD_SAVED_FILTERS:
                this._loadSavedFilters();
                this.emitUpdate();
                break;

            // sign out user
            case ActionTypes.SIGN_OUT_USER:
                case ActionTypes.REQUEST_SESSION_TIMEOUT:
                // clear pre-load flags
                this._watchlistLoadingCompleted = false;
                this._currentEntitlementsLoadingCompleted = false;
                this._clearFilters();
                this.emitUpdate();
                this._transitionPaths.push(Router.SIGN_IN);
                break;

            // address lookup
            case ActionTypes.REQUEST_LOOKUP_ADDRESS_BY_POSTCODE:
                this._addressLookupResult = null;
                this._addressLookupPending = true;
                this._error = null;
                this.emitUpdate();
                break;

            case ActionTypes.REQUEST_LOOKUP_ADDRESS_BY_POSTCODE_SUCCESS:
                this._addressLookupResult = new AddressLookupResult({}).fromDTO(action.userAddressDTO);
                this._addressLookupPending = false;
                this._error = null;
                this.emitUpdate();
                break;

            case ActionTypes.REQUEST_LOOKUP_ADDRESS_BY_POSTCODE_ERROR:
                this._addressLookupResult = null;
                this._addressLookupPending = false;
                this._error = action.error;
                this.emitUpdate();
                break;

            default:
                break;
        }
    }

    /**
     * Returns if content pre-loading is completed
     *
     * @returns {boolean}
     */
    isContentPreLoadingCompleted() {
        return this._deviceIdFingerprintingCompleted
            && this._translationsLoadingCompleted
            && this._appSettingsLoadingCompleted
            && this._platformArticlesLoadingCompleted
            && this._certificateListLoadingCompleted
            && (AuthStore.isSignedIn() ? this._userProfileLoadingCompleted : true)
            && (AuthStore.isSignedIn() ? this._recentlyViewedItemsLoadingCompleted : true)
            && (AuthStore.isSignedIn() ? this._watchlistLoadingCompleted : true)
            && (AuthStore.isSignedIn() ? this._currentEntitlementsLoadingCompleted : true);
    }

    /**
     * Set _appSettings
     *
     * @param appSettingDTOCollection
     * @private
     */
    _setAppSettings(appSettingDTOCollection) {
        this._appSettings = Immutable.OrderedMap();

        appSettingDTOCollection.forEach((appSettingDTO) => {
            let appSetting = new AppSetting({}).fromDTO(appSettingDTO);
            if (appSetting.settingKey) {
                // inject recently viewed page row if user signed in
                if (appSetting.settingKey === Config.STOREFRONT_SETTING_KEY && AuthStore.isSignedIn()) {
                    let settingValue = appSetting.settingValue.slice(0);
                    // add in the middle
                    // settingValue.splice(settingValue.length > 3 ? 3 : 0, 0, new PageRow({
                    //     type: PageRowType.RECENTLY_VIEWED,
                    //     value: 'recently_viewed'
                    // }));
                    // add at the end
                    settingValue.push(new PageRow({
                        type: PageRowType.RECENTLY_VIEWED,
                        value: 'recently_viewed'
                    }));
                    appSetting = appSetting.setSettingValue(settingValue);
                }

                // inject genre page rows to the end of list
                if (appSetting.settingKey === Config.STOREFRONT_SETTING_KEY) {
                    let settingValue = Array.isArray(appSetting.settingValue) ? appSetting.settingValue.slice(0) : [];
                    this._genreList.forEach(genre => {
                        settingValue.push(new PageRow({
                            type: PageRowType.GENRE,
                            value: genre
                        }));
                    });
                    appSetting = appSetting.setSettingValue(settingValue);
                }

                // add appSetting to store
                this._appSettings = this._appSettings.set(appSetting.settingKey, appSetting);
            }
        });
    }

    /**
     * Get _deviceId
     *
     * @returns {?string}
     */
    get deviceId() {
        return this._deviceId;
    }

    /**
     * Get _deviceType
     *
     * @returns {?string}
     */
    get deviceType() {
        return this._deviceType;
    }

    /**
     * Get _gaCID
     *
     * @returns {?string}
     */
    get gaCID() {
        return this._gaCID;
    }

    /**
     * Get _sessionId
     *
     * @returns {?string}
     */
    get sessionId() {
        return this._sessionId;
    }

    /**
     * Get _appSettings
     *
     * @returns {Immutable.OrderedMap<string, AppSetting>}
     */
    get appSettings() {
        return this._appSettings;
    }

    /**
     * Set _collectionList
     *
     * @param {Array<CollectionDTO>} collectionDTOCollection
     * @private
     */
    _setCollectionList(collectionDTOCollection) {
        this._collectionList = Immutable.OrderedMap();
        this._collectionListByTitle = Immutable.OrderedMap();
        this._emptyCollectionList = [];
        collectionDTOCollection.forEach((collectionDTO) => {
            let collection = new Collection({}).fromDTO(collectionDTO);
            if (collection.id) {
                this._collectionList = this._collectionList.set(collection.id, collection);
            }

            if (collectionDTO.TotalMediaItemsCount === 0) {
                this._emptyCollectionList.push(collectionDTO.Title);
            }

            // WARNING!! Vubiquity refuses to use or provide unique IDs for collections in their APIs. Possibility for Map
            // key collision and collection list item overwriting exists as there is no guarantee that title will be
            // unique enough. However _collectionListByTitle is more accurate for use in web-app as it has more items in
            // it when built from API. (so don't use _collectionList)
            this._collectionListByTitle = this._collectionListByTitle.set(collection.title, collection);
        });
    }

    /**
     * Get _collectionList
     *
     * @returns {Immutable.OrderedMap<string, Collection>}
     */
    get collectionList() {
        return this._collectionList;
    }

    /**
     * Get _collectionListByTitle
     *
     * @returns {Immutable.OrderedMap<string, Collection>}
     */
    get collectionListByTitle() {
        return this._collectionListByTitle;
    }

    /**
     * Set _promotionList
     *
     * @param {Array<CollectionDTO>} promotionDTOCollection
     * @private
     */
    _setPromotionList(promotionDTOCollection) {
        this._promotionList = Immutable.OrderedMap();
        this._promotionListByName = Immutable.OrderedMap();

        promotionDTOCollection.forEach((promotionDTO) => {
            let promotion = new Promotion({}).fromDTO(promotionDTO);
            if (promotion.id) {
                this._promotionList = this._promotionList.set(promotion.id, promotion);
                this._promotionListByName = this._promotionListByName.set(promotion.name, promotion);
            }
        });
    }

    /**
     * Get _promotionList
     *
     * @returns {Immutable.OrderedMap<string, Promotion>}
     */
    get promotionList() {
        return this._promotionList;
    }

    /**
     * Get _promotionListByName
     *
     * @returns {Immutable.OrderedMap<string, Promotion>}
     */
    get promotionListByName() {
        return this._promotionListByName;
    }

    /**
     * Set _mainNavigation
     *
     * @private
     */
    _setMainNavigation() {
        this._mainNavigation = Immutable.OrderedMap();

        Config.MAIN_NAVIGATION_SECTIONS.forEach(navigationSection => {
            let appSetting = this._appSettings.get(navigationSection.settingKey);
            navigationSection.excludedPromotions = navigationSection.excludedPromotions || [];
            navigationSection.importPromotionCollections = navigationSection.importPromotionCollections || [];

            if (appSetting) {
                let navigationItems = appSetting.settingValue
                    .reduce((navigationItems, pageRow) => {
                        // collection
                        if (pageRow.type === PageRowType.COLLECTION) {
                            let collection = this._collectionListByTitle.get(pageRow.value);
                            if (collection) {
                                navigationItems = navigationItems.set(pageRow.value, new MainNavigationItem({
                                    type: pageRow.type,
                                    identifier: pageRow.value,
                                    label: collection.title
                                }));
                            }
                        }
                        // promotion [PromotionItemType.MEDIA_ITEM]
                        if (pageRow.type === PageRowType.PROMOTION
                            && navigationSection.excludedPromotions.indexOf(pageRow.value) === -1) {
                            let promotion = this._promotionListByName.get(pageRow.value);
                            if (promotion) {
                                navigationItems = navigationItems.set(pageRow.value, new MainNavigationItem({
                                    type: pageRow.type,
                                    identifier: pageRow.value,
                                    label: promotion.title
                                }));
                            }
                        }
                        // collections from promotion [PromotionItemType.COLLECTIONS]
                        if (pageRow.type === PageRowType.PROMOTION
                            && navigationSection.importPromotionCollections.indexOf(pageRow.value) > -1) {
                            let promotion = this._promotionListByName.get(pageRow.value);
                            if (promotion) {
                                promotion.collectionItems.forEach(promotionItem => {
                                    let collection = this._collectionListByTitle.get(promotionItem.promotionalLink);
                                    if (collection) {
                                        navigationItems = navigationItems.set(promotionItem.promotionalLink, new MainNavigationItem({
                                            type: PageRowType.COLLECTION,
                                            identifier: promotionItem.promotionalLink,
                                            label: collection.title
                                        }));
                                    }
                                });
                            }
                        }
                        // search all
                        if (pageRow.type === PageRowType.SEARCH_ALL) {
                            navigationItems = navigationItems.set(pageRow.value, new MainNavigationItem({
                                type: pageRow.type,
                                label: pageRow.value
                            }));
                        }
                        return navigationItems;
                    }, Immutable.OrderedMap());

                this._mainNavigation = this._mainNavigation.set(navigationSection.settingKey, new MainNavigationSection({
                    key: navigationSection.settingKey,
                    items: navigationItems,
                    classification: navigationSection.classification,
                    displayGenres: typeof navigationSection.displayGenres === 'undefined' || navigationSection.displayGenres
                }));
            }
        });
    }

    /**
     * Get _mainNavigation
     *
     * @returns {Immutable.OrderedMap<string, MainNavigationSection>}
     */
    get mainNavigation() {
        return this._mainNavigation;
    }

    /**
     * Get navigation focused
     * @returns {boolean}
     */
    get navigationSearchFocused() {
        return this._navigationSearchFocused;
    }

    /**
     * Set translations
     *
     * @param {Object} translations
     * @private
     */
    _setTranslations(translations) {
        var lang = this._translations[Config.LOCALE];

        Object.keys(translations).forEach((key) => {
            if (typeof lang[key] !== 'undefined') {
                lang[key] = translations[key];
            }
        });
    }

    /**
     * Translate
     *
     * @param {string} key
     * @param {Object} params
     * @return {string}
     */
    translate(key, params = {}) {
        params = {
            ...params,
            ...Config
        };
        var lang = this._translations[Config.LOCALE];

        // if key doesn't exists return error string
        if (typeof lang[key] === 'undefined') {
            console.error(`** [app] Language key '${key}' not found.`);
            return key;
        }

        if (typeof lang[key] === 'function') {
            return lang[key](params);
        } else {
            return lang[key];
        }
    }

    /**
     * Set _genreList
     *
     * @param {Array<GenreDTO>} genreDTOCollection
     * @private
     */
    _setGenreList(genreDTOCollection) {
        this._genreList = Immutable.OrderedMap();
        genreDTOCollection.forEach((genreDTO) => {
            let genre = new Genre({}).fromDTO(genreDTO);
            if (genre.id) {
                this._genreList = this._genreList.set(genre.id, genre);
            }
        });
        Filter.setGenreFilters();
    }

    /**
     * Get _genreList
     *
     * @returns {Immutable.OrderedMap<string, Genre>}
     */
    get genreList() {
        return this._genreList;
    }

    /**
     * Set _certificateList
     *
     * @param {Array<CertificateDTO>} certificateDTOCollection
     * @private
     */
    _setCertificateList(certificateDTOCollection) {
        this._certificateList = Immutable.OrderedMap();
        certificateDTOCollection.forEach((certificateDTO) => {
            let certificate = new Certificate({}).fromDTO(certificateDTO);
            if (certificate.name) {
                this._certificateList = this._certificateList.set(certificate.name, certificate);
            }
        });
        Filter.setCertificateFilters();
    }

    /**
     * Get _certificateList
     *
     * @returns {Immutable.OrderedMap<string, Certificate>}
     */
    get certificateList() {
        return this._certificateList;
    }

    /**
     * Set _platformArticles
     *
     * @param {Array<PlatformArticleDTO>} platformArticleDTOCollection
     * @private
     */
    _setPlatformArticles(platformArticleDTOCollection) {
        platformArticleDTOCollection.forEach((platformArticleDTO) => {
            let platformArticle = new PlatformArticle({}).fromDTO(platformArticleDTO);
            if (platformArticle.contextCode) {
                this._platformArticles = this._platformArticles.set(platformArticle.contextCode, platformArticle);
            }
        });
    }

    /**
     * Get _platformArticles
     *
     * @returns {null|*}
     */
    get platformArticles() {
        return this._platformArticles;
    }

    /**
     * Get _transitionPaths
     * value can only be retrieved once
     *
     * @return {null|*}
     */
    get nextTransitionPath() {
        let nextTransitionPath = this._transitionPaths.pop();

        return nextTransitionPath || '/';
    }

    /**
     * Update active filters by adding or removing supplied filter key
     *
     * @param {string} filterKey
     * @private
     */
    _updateActiveFilters(filterKey) {
        if (this._activeFilters.get(filterKey)) {
            this._activeFilters = this._activeFilters.delete(filterKey);
        } else {
            if (filterKey.indexOf('OFFER_') !== -1) {
                this._activeFilters = this._activeFilters.delete(this._activeFilters.findKey((filter, existingKey) => {
                    return existingKey.indexOf('OFFER_') !== -1;
                }));
            }

            if (filterKey.indexOf('CERTIFICATE_') !== -1) {
                this._activeFilters = this._activeFilters.delete(this._activeFilters.findKey((filter, existingKey) => {
                    return existingKey.indexOf('CERTIFICATE_') !== -1;
                }));
            }

            if (filterKey.indexOf('YEAR_') !== -1) {
                this._activeFilters = this._activeFilters.delete(this._activeFilters.findKey((filter, existingKey) => {
                    return existingKey.indexOf('YEAR_') !== -1;
                }));
            }

            if (filterKey.indexOf('AUDIO_LANGUAGE_') !== -1) {
                this._activeFilters = this._activeFilters.delete(this._activeFilters.findKey((filter, existingKey) => {
                    return existingKey.indexOf('AUDIO_LANGUAGE_') !== -1;
                }));
            }

            let filterType = Filter.getType(filterKey);
            if (filterType && filterKey !== "YEAR_ANY" && filterKey !== "CERTIFICATE_ANY" && filterKey !== "AUDIO_LANGUAGE_ANY") {
                this._activeFilters = this._activeFilters.set(filterKey, filterType);
            }
        }

        var filterKeys = Object.keys(this._activeFilters.toObject());
        store.set("filters_active", filterKeys);
    }

    /**
     * Get _activeFilters
     *
     * @return {Immutable.OrderedMap<string, Object>}
     */
    get activeFilters() {
        return this._activeFilters;
    }

    /**
     * Get filters from local storage
     * method is called whenever new parts of information are loaded from API (genres, certificates..)
     *
     * @private
     */
    _getFiltersFromLocalStorage() {
        if (store.get("filters_active")) {
            setTimeout(() => { // add differing to ensure AppStore is accessible in Filter class
                store.get("filters_active").forEach((filterKey) => {
                    let filterType = Filter.getType(filterKey);
                    if (filterType) {
                        this._activeFilters = this._activeFilters.set(filterKey, filterType);
                    }
                });
            }, 0);
        }
    }

    /**
     * Reset filters
     *
     * @private
     */
    _resetFilters() {
        store.set("filters_saved", Object.keys(this._activeFilters.toObject()));
        this._activeFilters = Immutable.OrderedMap();
        store.set("filters_active", []);
    }

    /**
     * Clear filters
     *
     * @private
     */
    _clearFilters() {
        this._activeFilters = Immutable.OrderedMap();
        store.set("filters_saved", []);
        store.set("filters_active", []);
    }

    /**
     * Load saved filters
     *
     * @private
     */
    _loadSavedFilters() {
        if (store.get("filters_saved")) {
            this._activeFilters = Immutable.OrderedMap();
            store.get("filters_saved").forEach((filterKey) => {
                let filterType = Filter.getType(filterKey);
                if (filterType) {
                    this._activeFilters = this._activeFilters.set(filterKey, filterType);
                }
            });
        }
        store.set("filters_active", Object.keys(this._activeFilters.toObject()));
    }

    /**
     * Get address lookup result
     *
     * @returns {null|*}
     */
    get addressLookupResult() {
        return this._addressLookupResult;
    }

    /**
     * Get address lookup pending
     *
     * @returns {null|*}
     */
    get addressLookupPending() {
        return this._addressLookupPending;
    }

    /**
     * Get empty collection list
     *
     * @returns {null|*}
     */
    get emptyCollectionList() {
        return this._emptyCollectionList;
    }

    /**
     * Get _error
     *
     * @returns {null|*}
     */
    get error() {
        return this._error;
    }
}

export default new AppStore();
