"use strict";

import * as AppDispatcher from '../dispatchers/AppDispatcher';
import ActionTypes from './ActionTypes';
import ApiService from '../lib/api/ApiService';
import moment from 'moment/moment';

/**
 * Flux: AuthActions
 */
export default {
    // Sign up user
    signUpUser: (params = {}) => {
        let promise = ApiService.signUpUser(params);
        AppDispatcher.dispatchAsync(promise, {
            request: ActionTypes.REQUEST_SIGN_UP_USER,
            success: ActionTypes.REQUEST_SIGN_UP_USER_SUCCESS,
            failure: ActionTypes.REQUEST_SIGN_UP_USER_ERROR
        }, params);
    },

    // Sign in user
    signInUser: (params = {}) => {
        let promise = ApiService.signInUser(params)
            .then(userIdentityDTO => {
                return ({userIdentityDTO});
            })
            .catch(e => {
                throw e;
            });

        AppDispatcher.dispatchAsync(promise, {
            request: ActionTypes.REQUEST_SIGN_IN_USER,
            success: ActionTypes.REQUEST_SIGN_IN_USER_SUCCESS,
            failure: ActionTypes.REQUEST_SIGN_IN_USER_ERROR
        }, params);
    },

    // Sign up user then auto sign in
    signUpUserThenAutoSignIn: (params = {}) => {
        let promise = ApiService.signUpUser(params)
            .then(() => {
                return ApiService.signInUser(params);
            })
            .then(userIdentityDTO => {
                let authToken = userIdentityDTO && typeof userIdentityDTO.Token !== 'undefined' && typeof userIdentityDTO.UniqueIdentifier !== 'undefined'
                    ? btoa(userIdentityDTO.UniqueIdentifier + ":" + userIdentityDTO.Token) : null;
                let newsletterOptedIn = typeof params.newsletterOptedIn !== 'undefined'
                    ? params.newsletterOptedIn : false;

                return !authToken
                    ? {userIdentityDTO}
                    : ApiService.getUserPreferences({
                        ...params,
                        authToken: authToken
                    })
                        .then(userPreferences => ApiService.updateUserPreferences({
                            preferences: {
                                ...userPreferences,
                                commsAffiliatePost: newsletterOptedIn,
                                commsAffiliateEmail: newsletterOptedIn,
                                commsAffiliateSMS: newsletterOptedIn,
                                preferenceUpdateDate: moment().format()
                            },
                            authToken: authToken
                        }))
                        .catch(() => ({userIdentityDTO}))
                        .then(() => ({userIdentityDTO}));
            });

        AppDispatcher.dispatchAsync(promise, {
            request: ActionTypes.REQUEST_SIGN_UP_USER_THEN_AUTO_SIGN_IN,
            success: ActionTypes.REQUEST_SIGN_UP_USER_THEN_AUTO_SIGN_IN_SUCCESS,
            failure: ActionTypes.REQUEST_SIGN_UP_USER_THEN_AUTO_SIGN_IN_ERROR
        }, params);
    },

    // Accept terms and conditions
    acceptTermsAndConditions: (params = {}) => {
        let promise = ApiService.acceptTermsAndConditions(params);
        AppDispatcher.dispatchAsync(promise, {
            request: ActionTypes.REQUEST_ACCEPT_TERMS_AND_CONDITIONS,
            success: ActionTypes.REQUEST_ACCEPT_TERMS_AND_CONDITIONS_SUCCESS,
            failure: ActionTypes.REQUEST_ACCEPT_TERMS_AND_CONDITIONS_ERROR
        }, params);
    },

    // Resend activation email
    resendActivationEmail: (params = {}) => {
        let promise = ApiService.resendActivationEmail(params);
        AppDispatcher.dispatchAsync(promise, {
            request: ActionTypes.REQUEST_RESEND_ACTIVATION_EMAIL,
            success: ActionTypes.REQUEST_RESEND_ACTIVATION_EMAIL_SUCCESS,
            failure: ActionTypes.REQUEST_RESEND_ACTIVATION_EMAIL_ERROR
        }, params);
    },

    // Activate user
    activateUser: (params = {}) => {
        let promise = ApiService.activateUser(params);
        AppDispatcher.dispatchAsync(promise, {
            request: ActionTypes.REQUEST_ACTIVATE_USER,
            success: ActionTypes.REQUEST_ACTIVATE_USER_SUCCESS,
            failure: ActionTypes.REQUEST_ACTIVATE_USER_ERROR
        }, params);
    },

    // Get user profile information
    getUserProfile: (params = {}) => {
        let promise = ApiService.getUserProfile(params)
            .then(userProfileDTO => ({userProfileDTO}));

        AppDispatcher.dispatchAsync(promise, {
            request: ActionTypes.REQUEST_GET_USER_PROFILE,
            success: ActionTypes.REQUEST_GET_USER_PROFILE_SUCCESS,
            failure: ActionTypes.REQUEST_GET_USER_PROFILE_ERROR
        }, params);
    },

    // Update user profile information
    updateUserProfile: (params = {}) => {
        let promise = ApiService.updateUserProfile(params)
            .then(() => {
                return ApiService.getUserProfile(params);
            })
            .then(userProfileDTO => ({userProfileDTO}));

        AppDispatcher.dispatchAsync(promise, {
            request: ActionTypes.REQUEST_UPDATE_USER_PROFILE,
            success: ActionTypes.REQUEST_UPDATE_USER_PROFILE_SUCCESS,
            failure: ActionTypes.REQUEST_UPDATE_USER_PROFILE_ERROR
        }, params);
    },

    // Get user preferences information
    getUserPreferences: (params = {}) => {
        let promise = ApiService.getUserPreferences({
            ...params
        }).then(userPreferencesDTO => ({userPreferencesDTO}));

        AppDispatcher.dispatchAsync(promise, {
            request: ActionTypes.REQUEST_GET_USER_PREFERENCES,
            success: ActionTypes.REQUEST_GET_USER_PREFERENCES_SUCCESS,
            failure: ActionTypes.REQUEST_GET_USER_PREFERENCES_ERROR
        }, params);
    },

    // Update user preferences information
    updateUserPreferences: (params = {}) => {
        let newsletterOptedIn = typeof params.newsletterOptedIn !== 'undefined'
            ? params.newsletterOptedIn : false;

        let promise = ApiService.getUserPreferences({
            ...params
        })
            .then(userPreferences => ApiService.updateUserPreferences({
                preferences: {
                    ...userPreferences,
                    commsAffiliatePost: newsletterOptedIn,
                    commsAffiliateEmail: newsletterOptedIn,
                    commsAffiliateSMS: newsletterOptedIn,
                    preferenceUpdateDate: moment().format()
                },
                authToken: params.authToken
            }));

        AppDispatcher.dispatchAsync(promise, {
            request: ActionTypes.REQUEST_UPDATE_USER_PREFERENCES,
            success: ActionTypes.REQUEST_UPDATE_USER_PREFERENCES_SUCCESS,
            failure: ActionTypes.REQUEST_UPDATE_USER_PREFERENCES_ERROR
        }, params);
    },

    // Update user profile and password
    updateUserProfileAndPassword: (params = {}) => {
        let promise = ApiService.updateUserProfile(params)
            .then(() => {
                if (params.oldPassword) {
                    return ApiService.changeUserPassword(params)
                        .then(() => {
                            return ApiService.signInUser(params);
                        });
                }
            })
            .then(userIdentityDTO => {
                if (userIdentityDTO) {
                    return {userIdentityDTO: userIdentityDTO, userProfileDTO: null};
                } else {
                    return ApiService.getUserProfile(params)
                        .then(userProfileDTO => ({userIdentityDTO: null, userProfileDTO: userProfileDTO}));
                }
            });

        AppDispatcher.dispatchAsync(promise, {
            request: ActionTypes.REQUEST_UPDATE_USER_PROFILE_AND_PASSWORD,
            success: ActionTypes.REQUEST_UPDATE_USER_PROFILE_AND_PASSWORD_SUCCESS,
            failure: ActionTypes.REQUEST_UPDATE_USER_PROFILE_AND_PASSWORD_ERROR
        }, params);
    },

    // Change user password
    changeUserPassword: (params = {}) => {
        let promise = ApiService.changeUserPassword(params);
        AppDispatcher.dispatchAsync(promise, {
            request: ActionTypes.REQUEST_CHANGE_USER_PASSWORD,
            success: ActionTypes.REQUEST_CHANGE_USER_PASSWORD_SUCCESS,
            failure: ActionTypes.REQUEST_CHANGE_USER_PASSWORD_ERROR
        }, params);
    },

    // Sign out user
    signOutUser: () => {
        AppDispatcher.dispatch(ActionTypes.SIGN_OUT_USER);
    },

        // Session timeout
        timeoutUser: () => {
            AppDispatcher.dispatch(ActionTypes.REQUEST_SESSION_TIMEOUT);
        },

    // Send reset user password email
    sendResetUserPasswordEmail: (params = {}) => {
        let promise = ApiService.sendResetUserPasswordEmail(params);
        AppDispatcher.dispatchAsync(promise, {
            request: ActionTypes.REQUEST_SEND_RESET_USER_PASSWORD_EMAIL,
            success: ActionTypes.REQUEST_SEND_RESET_USER_PASSWORD_EMAIL_SUCCESS,
            failure: ActionTypes.REQUEST_SEND_RESET_USER_PASSWORD_EMAIL_ERROR
        }, params);
    },

    // Reset user password
    resetUserPassword: (params = {}) => {
        let promise = ApiService.resetUserPassword(params);
        AppDispatcher.dispatchAsync(promise, {
            request: ActionTypes.REQUEST_RESET_USER_PASSWORD,
            success: ActionTypes.REQUEST_RESET_USER_PASSWORD_SUCCESS,
            failure: ActionTypes.REQUEST_RESET_USER_PASSWORD_ERROR
        }, params);
    },

    /**
     * Add payment method
     *
     * @param {Object} params
     */
    addPaymentMethod: (params = {}) => {
        let promise = ApiService.createStripeCardToken(params)
            .then((stripeTokenDTO) => {
                return ApiService.createPaymentProviderCustomer({
                    ...params,
                    stripeTokenId: stripeTokenDTO.id
                });
            })
            .then(() => {
                return ApiService.getUserProfile(params);
            })
            .then((userProfileDTO) => {
                return ApiService
                    .getCustomerPaymentDetails()
                    .then(customerPaymentDetailsDTO => ({userProfileDTO, customerPaymentDetailsDTO}));
            });

        AppDispatcher.dispatchAsync(promise, {
            request: ActionTypes.REQUEST_ADD_PAYMENT_METHOD,
            success: ActionTypes.REQUEST_ADD_PAYMENT_METHOD_SUCCESS,
            failure: ActionTypes.REQUEST_ADD_PAYMENT_METHOD_ERROR
        }, params);
    },

    /**
     * Update payment method
     *
     * @param {Object} params
     */
    updatePaymentMethod: (params = {}) => {
        let promise = ApiService.createStripeCardToken(params)
            .then((stripeTokenDTO) => {
                return ApiService.updatePaymentProviderCustomer({
                    ...params,
                    stripeTokenId: stripeTokenDTO.id
                });
            })
            .then(() => {
                return ApiService.getUserProfile(params);
            })
            .then((userProfileDTO) => {
                return ApiService
                    .getCustomerPaymentDetails()
                    .then(customerPaymentDetailsDTO => ({userProfileDTO, customerPaymentDetailsDTO}));
            });

        AppDispatcher.dispatchAsync(promise, {
            request: ActionTypes.REQUEST_UPDATE_PAYMENT_METHOD,
            success: ActionTypes.REQUEST_UPDATE_PAYMENT_METHOD_SUCCESS,
            failure: ActionTypes.REQUEST_UPDATE_PAYMENT_METHOD_ERROR
        }, params);
    },

    /**
     * Update payment method (Stripe v3)
     *
     * @param {Object} params
     */
    updatePaymentMethodV3: (params = {}) => {
        const {cardElement, confirmCardSetup, city, line1, line2, postal_code, cardName, email} = params;

        const promise = ApiService.getSetupIntentSecret()
            .then(response => {
                if (!response.Success) {
                    throw new Error(response.Message);
                }
                return response.ClientSecret;
            })
            .then(clientSecret => {
                return confirmCardSetup(clientSecret, {
                    payment_method: {
                        card: cardElement,
                        billing_details: {
                            address: {
                                city: city,
                                line1: line1,
                                line2: line2,
                                postal_code: postal_code,
                            },
                            name: cardName,
                            email: email

                        }
                    }
                })
                    .then(response => {
                        if (response.error) {
                            throw new Error(response.error.message);
                        }
                        return response.setupIntent;
                    });
            })
            .then(setupIntent => {
                return ApiService.updatePaymentProviderCustomer({
                    stripeTokenId: setupIntent.payment_method,
                    email: email
                });
            })
            .then(() => {
                return ApiService.getUserProfile(params);
            })
            .then((userProfileDTO) => {
                return ApiService
                    .getCustomerPaymentDetails()
                    .then(customerPaymentDetailsDTO => ({userProfileDTO, customerPaymentDetailsDTO}));
            });

        AppDispatcher.dispatchAsync(promise, {
            request: ActionTypes.REQUEST_UPDATE_PAYMENT_METHOD,
            success: ActionTypes.REQUEST_UPDATE_PAYMENT_METHOD_SUCCESS,
            failure: ActionTypes.REQUEST_UPDATE_PAYMENT_METHOD_ERROR
        }, params);
    },

    /**
     * Remove payment method
     *
     * @param {Object} params
     */
    removePaymentMethod: (params = {}) => {
        let promise = ApiService.removePaymentProviderCustomer(params);

        AppDispatcher.dispatchAsync(promise, {
            request: ActionTypes.REQUEST_REMOVE_PAYMENT_METHOD,
            success: ActionTypes.REQUEST_REMOVE_PAYMENT_METHOD_SUCCESS,
            failure: ActionTypes.REQUEST_REMOVE_PAYMENT_METHOD_ERROR
        }, params);
    },

    /**
     * Get customer payment details
     *
     * @param {Object} params
     */
    getCustomerPaymentDetails: (params = {}) => {
        let promise = ApiService
            .getCustomerPaymentDetails(params)
            .then(customerPaymentDetailsDTO => ({customerPaymentDetailsDTO}));

        AppDispatcher.dispatchAsync(promise, {
            request: ActionTypes.REQUEST_GET_CUSTOMER_PAYMENT_DETAILS,
            success: ActionTypes.REQUEST_GET_CUSTOMER_PAYMENT_DETAILS_SUCCESS,
            failure: ActionTypes.REQUEST_GET_CUSTOMER_PAYMENT_DETAILS_ERROR
        }, params);
    }
};
