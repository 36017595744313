"use strict";

import keyMirror from 'keymirror';

/**
 * Flux: action types
 */
let ActionTypes = keyMirror({
    // App
    REQUEST_GET_APP_SETTINGS: null,
    REQUEST_GET_APP_SETTINGS_SUCCESS: null,
    REQUEST_GET_APP_SETTINGS_ERROR: null,

    REQUEST_GET_COLLECTION_LIST: null,
    REQUEST_GET_COLLECTION_LIST_SUCCESS: null,
    REQUEST_GET_COLLECTION_LIST_ERROR: null,

    REQUEST_GET_CERTIFICATE_LIST: null,
    REQUEST_GET_CERTIFICATE_LIST_SUCCESS: null,
    REQUEST_GET_CERTIFICATE_LIST_ERROR: null,

    REQUEST_GET_TRANSLATIONS: null,
    REQUEST_GET_TRANSLATIONS_SUCCESS: null,
    REQUEST_GET_TRANSLATIONS_ERROR: null,

    REQUEST_GET_PLATFORM_ARTICLES: null,
    REQUEST_GET_PLATFORM_ARTICLES_SUCCESS: null,
    REQUEST_GET_PLATFORM_ARTICLES_ERROR: null,

    STORE_DEVICE_TYPE: null,
    STORE_FINGERPRINT_DEVICE_ID: null,
    STORE_ROUTER_TRANSITION_PATH: null,
    TOGGLE_FILTER: null,
    LOAD_SAVED_FILTERS: null,
    RESET_FILTERS: null,
    TOGGLE_NAVIGATION_FOCUS: null,

    REQUEST_LOOKUP_ADDRESS_BY_POSTCODE: null,
    REQUEST_LOOKUP_ADDRESS_BY_POSTCODE_SUCCESS: null,
    REQUEST_LOOKUP_ADDRESS_BY_POSTCODE_ERROR: null,

    // UI
    SHOW_CUSTOM_OVERLAY: null,
    CLOSE_CUSTOM_OVERLAY: null,

    // Auth
    REQUEST_SIGN_UP_USER: null,
    REQUEST_SIGN_UP_USER_SUCCESS: null,
    REQUEST_SIGN_UP_USER_ERROR: null,

    REQUEST_SIGN_UP_USER_THEN_AUTO_SIGN_IN: null,
    REQUEST_SIGN_UP_USER_THEN_AUTO_SIGN_IN_SUCCESS: null,
    REQUEST_SIGN_UP_USER_THEN_AUTO_SIGN_IN_ERROR: null,

    REQUEST_RESEND_ACTIVATION_EMAIL: null,
    REQUEST_RESEND_ACTIVATION_EMAIL_SUCCESS: null,
    REQUEST_RESEND_ACTIVATION_EMAIL_ERROR: null,

    REQUEST_ACTIVATE_USER: null,
    REQUEST_ACTIVATE_USER_SUCCESS: null,
    REQUEST_ACTIVATE_USER_ERROR: null,

    REQUEST_ACCEPT_TERMS_AND_CONDITIONS: null,
    REQUEST_ACCEPT_TERMS_AND_CONDITIONS_SUCCESS: null,
    REQUEST_ACCEPT_TERMS_AND_CONDITIONS_ERROR: null,

    REQUEST_SIGN_IN_USER: null,
    REQUEST_SIGN_IN_USER_SUCCESS: null,
    REQUEST_SIGN_IN_USER_ERROR: null,

    REQUEST_GET_USER_PROFILE: null,
    REQUEST_GET_USER_PROFILE_SUCCESS: null,
    REQUEST_GET_USER_PROFILE_ERROR: null,

    REQUEST_UPDATE_USER_PROFILE: null,
    REQUEST_UPDATE_USER_PROFILE_SUCCESS: null,
    REQUEST_UPDATE_USER_PROFILE_ERROR: null,

    REQUEST_GET_USER_PREFERENCES: null,
    REQUEST_GET_USER_PREFERENCES_SUCCESS: null,
    REQUEST_GET_USER_PREFERENCES_ERROR: null,

    REQUEST_UPDATE_USER_PREFERENCES: null,
    REQUEST_UPDATE_USER_PREFERENCES_SUCCESS: null,
    REQUEST_UPDATE_USER_PREFERENCES_ERROR: null,

    REQUEST_CHANGE_USER_PASSWORD: null,
    REQUEST_CHANGE_USER_PASSWORD_SUCCESS: null,
    REQUEST_CHANGE_USER_PASSWORD_ERROR: null,

    REQUEST_UPDATE_USER_PROFILE_AND_PASSWORD: null,
    REQUEST_UPDATE_USER_PROFILE_AND_PASSWORD_SUCCESS: null,
    REQUEST_UPDATE_USER_PROFILE_AND_PASSWORD_ERROR: null,

    SIGN_OUT_USER: null,

    REQUEST_SEND_RESET_USER_PASSWORD_EMAIL: null,
    REQUEST_SEND_RESET_USER_PASSWORD_EMAIL_SUCCESS: null,
    REQUEST_SEND_RESET_USER_PASSWORD_EMAIL_ERROR: null,

    REQUEST_RESET_USER_PASSWORD: null,
    REQUEST_RESET_USER_PASSWORD_SUCCESS: null,
    REQUEST_RESET_USER_PASSWORD_ERROR: null,

    REQUEST_ADD_PAYMENT_METHOD: null,
    REQUEST_ADD_PAYMENT_METHOD_SUCCESS: null,
    REQUEST_ADD_PAYMENT_METHOD_ERROR: null,

    REQUEST_UPDATE_PAYMENT_METHOD: null,
    REQUEST_UPDATE_PAYMENT_METHOD_SUCCESS: null,
    REQUEST_UPDATE_PAYMENT_METHOD_ERROR: null,

    REQUEST_REMOVE_PAYMENT_METHOD: null,
    REQUEST_REMOVE_PAYMENT_METHOD_SUCCESS: null,
    REQUEST_REMOVE_PAYMENT_METHOD_ERROR: null,

    REQUEST_GET_CUSTOMER_PAYMENT_DETAILS: null,
    REQUEST_GET_CUSTOMER_PAYMENT_DETAILS_SUCCESS: null,
    REQUEST_GET_CUSTOMER_PAYMENT_DETAILS_ERROR: null,

    // Notification
    DISPLAY_NOTIFICATION: null,
    HIDE_NOTIFICATION: null,

    // Media
    REQUEST_SEARCH_MEDIA: null,
    REQUEST_SEARCH_MEDIA_SUCCESS: null,
    REQUEST_SEARCH_MEDIA_ERROR: null,

    REQUEST_GET_MEDIA_ITEM: null,
    REQUEST_GET_MEDIA_ITEM_SUCCESS: null,
    REQUEST_GET_MEDIA_ITEM_ERROR: null,

    REQUEST_GET_MEDIA_ITEM_RECOMMENDATIONS: null,
    REQUEST_GET_MEDIA_ITEM_RECOMMENDATIONS_SUCCESS: null,
    REQUEST_GET_MEDIA_ITEM_RECOMMENDATIONS_ERROR: null,

    REQUEST_GET_COLLECTION_MEDIA: null,
    REQUEST_GET_COLLECTION_MEDIA_SUCCESS: null,
    REQUEST_GET_COLLECTION_MEDIA_ERROR: null,

    REQUEST_GET_PROMOTION_MEDIA: null,
    REQUEST_GET_PROMOTION_MEDIA_SUCCESS: null,
    REQUEST_GET_PROMOTION_MEDIA_ERROR: null,

    REQUEST_GET_GENRE_MEDIA_FILTERED: null,
    REQUEST_GET_GENRE_MEDIA_FILTERED_SUCCESS: null,
    REQUEST_GET_GENRE_MEDIA_FILTERED_ERROR: null,

    REQUEST_GET_CAST_MEDIA: null,
    REQUEST_GET_CAST_MEDIA_SUCCESS: null,
    REQUEST_GET_CAST_MEDIA_ERROR: null,

    REQUEST_GET_WATCHLIST_MEDIA: null,
    REQUEST_GET_WATCHLIST_MEDIA_SUCCESS: null,
    REQUEST_GET_WATCHLIST_MEDIA_ERROR: null,

    REQUEST_GET_LIBRARY_MEDIA: null,
    REQUEST_GET_LIBRARY_MEDIA_SUCCESS: null,
    REQUEST_GET_LIBRARY_MEDIA_ERROR: null,

    REQUEST_GET_RECENTLY_VIEWED_MEDIA: null,
    REQUEST_GET_RECENTLY_VIEWED_MEDIA_SUCCESS: null,
    REQUEST_GET_RECENTLY_VIEWED_MEDIA_ERROR: null,

    REQUEST_RATE_MEDIA_ITEM: null,
    REQUEST_RATE_MEDIA_ITEM_SUCCESS: null,
    REQUEST_RATE_MEDIA_ITEM_ERROR: null,

    // Watchlist
    REQUEST_GET_WATCHLIST: null,
    REQUEST_GET_WATCHLIST_SUCCESS: null,
    REQUEST_GET_WATCHLIST_ERROR: null,

    REQUEST_ADD_MEDIA_ITEM_TO_WATCHLIST: null,
    REQUEST_ADD_MEDIA_ITEM_TO_WATCHLIST_SUCCESS: null,
    REQUEST_ADD_MEDIA_ITEM_TO_WATCHLIST_ERROR: null,

    REQUEST_REMOVE_MEDIA_ITEM_FROM_WATCHLIST: null,
    REQUEST_REMOVE_MEDIA_ITEM_FROM_WATCHLIST_SUCCESS: null,
    REQUEST_REMOVE_MEDIA_ITEM_FROM_WATCHLIST_ERROR: null,

    REQUEST_INCREMENT_PRE_ORDERED_MEDIA_ITEM_ALERT_DISPLAY_COUNT: null,
    REQUEST_INCREMENT_PRE_ORDERED_MEDIA_ITEM_ALERT_DISPLAY_COUNT_SUCCESS: null,
    REQUEST_INCREMENT_PRE_ORDERED_MEDIA_ITEM_ALERT_DISPLAY_COUNT_ERROR: null,

    CLOSE_PRE_ORDERED_MEDIA_ITEM_ALERT: null,

    REQUEST_CANCEL_PRE_ORDERED_MEDIA_ITEM_ALERT: null,
    REQUEST_CANCEL_PRE_ORDERED_MEDIA_ITEM_ALERT_SUCCESS: null,
    REQUEST_CANCEL_PRE_ORDERED_MEDIA_ITEM_ALERT_ERROR: null,

    // Purchase
    ADD_MEDIA_ITEM_OFFER_TO_BUNDLE_SHOPPING_CART: null,
    REMOVE_MEDIA_ITEM_OFFER_FROM_BUNDLE_SHOPPING_CART: null,

    REQUEST_GET_BUNDLE_SHOPPING_CART_VALUE: null,
    REQUEST_GET_BUNDLE_SHOPPING_CART_VALUE_SUCCESS: null,
    REQUEST_GET_BUNDLE_SHOPPING_CART_VALUE_ERROR: null,

    REQUEST_FAST_CHECKOUT_MEDIA_ITEM_OFFER: null,
    REQUEST_FAST_CHECKOUT_MEDIA_ITEM_OFFER_SUCCESS: null,
    REQUEST_FAST_CHECKOUT_MEDIA_ITEM_OFFER_ERROR: null,

    REQUEST_FAST_CHECKOUT_BUNDLE_OFFER: null,
    REQUEST_FAST_CHECKOUT_BUNDLE_OFFER_SUCCESS: null,
    REQUEST_FAST_CHECKOUT_BUNDLE_OFFER_ERROR: null,

    CANCEL_CHECKOUT: null,

    REQUEST_CHARGE_BASKET: null,
    REQUEST_CHARGE_BASKET_SUCCESS: null,
    REQUEST_CHARGE_BASKET_ERROR: null,

    REQUEST_VERIFY_VOUCHER: null,
    REQUEST_VERIFY_VOUCHER_SUCCESS: null,
    REQUEST_VERIFY_VOUCHER_ERROR: null,

    REQUEST_CREATE_PURCHASE_ORDER_VOD_ITEM: null,
    REQUEST_CREATE_PURCHASE_ORDER_VOD_ITEM_SUCCESS: null,
    REQUEST_CREATE_PURCHASE_ORDER_VOD_ITEM_ERROR: null,

    // Entitlement
    REQUEST_GET_CURRENT_ENTITLEMENTS: null,
    REQUEST_GET_CURRENT_ENTITLEMENTS_SUCCESS: null,
    REQUEST_GET_CURRENT_ENTITLEMENTS_ERROR: null,

    REQUEST_GET_HISTORY_ENTITLEMENTS: null,
    REQUEST_GET_HISTORY_ENTITLEMENTS_SUCCESS: null,
    REQUEST_GET_HISTORY_ENTITLEMENTS_ERROR: null,

    REQUEST_GET_RECENTLY_VIEWED_ITEMS: null,
    REQUEST_GET_RECENTLY_VIEWED_ITEMS_SUCCESS: null,
    REQUEST_GET_RECENTLY_VIEWED_ITEMS_ERROR: null,

    REQUEST_GET_AMS_LICENSE: null,
    REQUEST_GET_AMS_LICENSE_SUCCESS: null,
    REQUEST_GET_AMS_LICENSE_ERROR: null,

    REQUEST_GET_TRAILER_LICENSE: null,
    REQUEST_GET_TRAILER_LICENSE_SUCCESS: null,
    REQUEST_GET_TRAILER_LICENSE_ERROR: null,

    REQUEST_REGISTER_DEVICE: null,
    REQUEST_REGISTER_DEVICE_SUCCESS: null,
    REQUEST_REGISTER_DEVICES_ERROR: null,

    REQUEST_GET_REGISTERED_DEVICES: null,
    REQUEST_GET_REGISTERED_DEVICES_SUCCESS: null,
    REQUEST_GET_REGISTERED_DEVICES_ERROR: null,

    REQUEST_CHANGE_REGISTERED_DEVICE_NAME: null,
    REQUEST_CHANGE_REGISTERED_DEVICE_NAME_SUCCESS: null,
    REQUEST_CHANGE_REGISTERED_DEVICE_NAME_ERROR: null,

    REQUEST_REMOVE_REGISTERED_DEVICE: null,
    REQUEST_REMOVE_REGISTERED_DEVICE_SUCCESS: null,
    REQUEST_REMOVE_REGISTERED_DEVICE_ERROR: null,

    START_MEDIA_ITEM_PLAYBACK: null,
    STOP_MEDIA_ITEM_PLAYBACK: null,

    REQUEST_CREATE_PLAYBACK_LOG: null,
    REQUEST_CREATE_PLAYBACK_LOG_SUCCESS: null,
    REQUEST_CREATE_PLAYBACK_LOG_ERROR: null,

    // Analytics
    TRACK_PAGE_VIEW: null,
    TRACK_EVENT: null,
    TRACK_CUSTOM_EVENT: null,

    // STB Pairing
    REQUEST_STB_PAIRING_PROCESS_ENABLED: null,
    REQUEST_STB_PAIRING_PROCESS_DISABLED: null,

    REQUEST_VALIDATE_STB_CODE: null,
    REQUEST_VALIDATE_STB_CODE_SUCCESS: null,
    REQUEST_VALIDATE_STB_CODE_ERROR: null,

    REQUEST_CONSUME_STB_CODE: null,
    REQUEST_CONSUME_STB_CODE_SUCCESS: null,
    REQUEST_CONSUME_STB_CODE_ERROR: null,

    REQUEST_CONFIRM_STB_REGISTRATION: null,
    REQUEST_CONFIRM_STB_REGISTRATION_SUCCESS: null,
    REQUEST_CONFIRM_STB_REGISTRATION_ERROR: null,

    REQUEST_STB_PAIRING_PROCESS_FINISHED: null,
    REQUEST_SESSION_TIMEOUT: null
});

export default ActionTypes;
