import placeholder_poster from '../../assets/images/tile-placeholder-poster.png';
import placeholder_backdrop from '../../assets/images/tile-placeholder-backdrop.png';

/**
 * App config
 */
export default {
    // General
    VERSION: process.env.VERSION,
    BUILD: process.env.BUILD,
    ENVIRONMENT: process.env.ENVIRONMENT,
    ROOT: '/',
    BASE_URL: process.env.BASE_URL,
    LOCALE: 'en',
    SHOW_LOGGER_MSGS: process.env.SHOW_LOGGER_MSGS && process.env.SHOW_LOGGER_MSGS === 'true',
    PLACEHOLDER_IMAGE_POSTER: placeholder_poster,
    PLACEHOLDER_IMAGE_BACKDROP: placeholder_backdrop,
    PLACEHOLDER_IMAGE_BACKGROUND: placeholder_backdrop,
    PLACEHOLDER_IMAGE_TRAILER: placeholder_backdrop,
    START_YEAR: 2018,
    NUMBER_OF_YEARS: 11,
    SEARCH_RESULTS_LIMIT: 21,
    STOREFRONT_INITIAL_PAGE_ROWS_COUNT: 6,
    STOREFRONT_PAGE_ROWS_BATCH_COUNT: 3,
    STOREFRONT_GENRE_PAGE_ROW_MEDIA_LIMIT: 30,
    MEDIA_CAROUSEL_TALLY_MAX_ITEMS: 24,
    DEFAULT_DEVICE_TYPE: 'WindowsOnX86',
    USE_DEFAULT_DEVICE_TYPE: false,
    VUBIQUITY_ANALYTICS_ENABLED: true,
    REGISTERED_DEVICE_LIMIT: 5,
    MEDIA_PLAYER_VOLUME: 75,
    MEDIA_PLAYER_CONTROLS_AUTO_HIDE: 5500,
    MAIN_NAVIGATION_SECTIONS: [
        {
            settingKey: 'MoviePageRowOrder', classification: 'movie',
            excludedPromotions: ['COLLECTIONS_MOVIE']
        },
        {
            settingKey: 'TVPageRowOrder', classification: 'series',
            excludedPromotions: ['COLLECTIONS_TV']
        },
        {
            settingKey: 'StorefrontPageRowOrder', classification: null,
            excludedPromotions: ['COLLECTIONS_STOREFRONT', 'FEATURED_STOREFRONT', 'RECOMMENDATIONS_STOREFRONT'],
            importPromotionCollections: ['COLLECTIONS_STOREFRONT'],
            displayGenres: false
        }
    ],
    STOREFRONT_SETTING_KEY: 'StorefrontPageRowOrder',
    MEDIA_PLAYER_TYPE: 'shaka',
    MEDIA_PLAYER_TRAILER_TYPE: 'shaka',
    SHAKA_MEDIA_PLAYER_VERSION: '4.7.8',

    // API
    PROXY_API_URL: process.env.PROXY_API_URL,
    VUB_API_URL: process.env.VUB_API_URL,
    VUB_LOGWATCH_URL: process.env.VUB_LOGWATCH_URL,
    VUB_LICENSOR_URL: process.env.VUB_LICENSOR_URL,
    STRIPE_API_URL: 'https://api.stripe.com',
    STRIPE_V3_SDK: '', // TODO
    VUBIQUITY_DEVICE_FINGERPRINT_LIBRARY: '//vucscdnprodne.azureedge.net/scripts/content_services/device_management/vudevicefp-debug.js',
    STRIPE_USE_V3: true,

    // Identity
    APP_SETTINGS_GROUP: 'Clients-Web',
    STRIPE_API_PK: process.env.STRIPE_API_PK,
    GA_TRACKING_ID: process.env.GA_TRACKING_ID,
    GOOGLE_PLAY_STORE_APP_URL: 'https://play.google.com/store/apps/details?id=com.virginmedia.vmstore',
    APPLE_STORE_APP_URL: 'https://itunes.apple.com/gb/developer/virgin-media/id449156077',
    RE_CAPTCHA_SITE_KEY: process.env.RE_CAPTCHA_SITE_KEY,
    IDENTITY_SECURITY_KEY: process.env.IDENTITY_SECURITY_KEY,
    IMAGE_OPTIMISATION_LEVEL: '?q=80',
    ID: 'spoiledmilk.web.virginmedia',
    AFFILIATE_ID: 12,
    AFFILIATE_NAME: 'VirginMedia',
    TRUSTED_APPLICATION_TOKEN: btoa('2itAxeJVTy'),
    TRUSTED_APPLICATION_NAME: 'VirginMediaWebSite',
    CUSTOMER_SUPPORT_EMAIL: 'customerservice@virginmediastore.com',
    CUSTOMER_SUPPORT_TELEPHONE: '0800 027 0801',

    // get uk address api
    GET_ADDRESS_IO_URL: process.env.GET_ADDRESS_IO_URL,
    GET_ADDRESS_IO_API_KEY: process.env.GET_ADDRESS_IO_API_KEY,

    // VISION 360 API
    VISION360_API_URL: process.env.VISION360_API_URL,
    VISION360_AUTH_KEY: process.env.VISION360_AUTH_KEY,

    //tile popup bubble debug
    // development variable is added for debugging in order to not remove popup element from DOM
    TILE_POPUP_BUBBLE_DEBUG_ENABLED: process.env.TILE_POPUP_BUBBLE_DEBUG_ENABLED && process.env.TILE_POPUP_BUBBLE_DEBUG_ENABLED === 'true',
};
