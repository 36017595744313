"use strict";

import Immutable from 'immutable';
import store from 'store';
import moment from 'moment';
import {waitFor} from '../dispatchers/AppDispatcher';
import MediaBucket from '../types/MediaBucket';
import WatchlistItem from '../types/WatchlistItem';
import {MediaItemClassification} from '../types/MediaItem';
import ActionTypes from '../actions/ActionTypes';
import BaseStore from './BaseStore';
import WatchlistStore from './WatchlistStore';
import PurchaseStore from './PurchaseStore';
import EntitlementStore from './EntitlementStore';

/**
 * Flux: MediaStore
 */
class MediaStore extends BaseStore {
    /**
     * Bucket storage
     *
     * @type {Immutable.Map<string, MediaBucket>} _bucketStorage
     * @private
     */
    _bucketStorage = Immutable.Map();

    /**
     * @type {string} _searchQueryString
     * @type {string} _searchMediaBucketKey
     * @type {number} _searchResultTotalCount
     * @private
     */
    _searchQueryString = '';
    _searchMediaBucketKey = '';
    _searchResultTotalCount = 0;

    _genreQueryString = '';
    _genreMediaBucketKey = '';
    _genreResultTotalCount = 0;
    _genreId = null;
    _genreClassification = null;

    /**
     * Constructor
     */
    constructor() {
        super();
        this.subscribe(() => this._registerToActions.bind(this));
    }

    /**
     * Flux: register store to actions
     *
     * @param action
     * @private
     */
    _registerToActions(action) {
        switch (action.type) {
            // search media
            case ActionTypes.REQUEST_SEARCH_MEDIA:
                this._createMediaBucket(action.uuid);
                this._searchQueryString = action.query;
                this._searchMediaBucketKey = action.uuid;
                this._searchResultTotalCount = 0;
                this._error = null;
                this.emitUpdate();
                break;

            case ActionTypes.REQUEST_SEARCH_MEDIA_SUCCESS:
                this._fillMediaBucket(action.uuid, action.mediaItemDTOCollection, action.primaryMediaItemIds, action.resultTotalCount);
                this._searchResultTotalCount = action.resultTotalCount;
                this._error = null;
                this.emitUpdate();
                break;

            case ActionTypes.REQUEST_SEARCH_MEDIA_ERROR:
                this._error = action.error;
                this.emitUpdate();
                break;

            // get media item
            case ActionTypes.REQUEST_GET_MEDIA_ITEM:
                this._createMediaBucket(action.uuid);
                this._error = null;
                this.emitUpdate();
                break;

            case ActionTypes.REQUEST_GET_MEDIA_ITEM_SUCCESS:
                this._fillMediaBucket(action.uuid, action.mediaItemDTOCollection, action.primaryMediaItemIds);
                this._error = null;
                this.emitUpdate();
                break;

            case ActionTypes.REQUEST_GET_MEDIA_ITEM_ERROR:
                this._error = action.error;
                this.emitUpdate();
                break;

            // get media item recommendations
            case ActionTypes.REQUEST_GET_MEDIA_ITEM_RECOMMENDATIONS:
                this._createMediaBucket(action.uuid);
                this._error = null;
                this.emitUpdate();
                break;

            case ActionTypes.REQUEST_GET_MEDIA_ITEM_RECOMMENDATIONS_SUCCESS:
                this._fillMediaBucket(action.uuid, action.mediaItemDTOCollection, action.primaryMediaItemIds);
                this._error = null;
                this.emitUpdate();
                break;

            case ActionTypes.REQUEST_GET_MEDIA_ITEM_RECOMMENDATIONS_ERROR:
                this._error = action.error;
                this.emitUpdate();
                break;

            // get collection media
            case ActionTypes.REQUEST_GET_COLLECTION_MEDIA:
                this._createMediaBucket(action.uuid);
                this.emitUpdate();
                break;

            case ActionTypes.REQUEST_GET_COLLECTION_MEDIA_SUCCESS:
                this._fillMediaBucket(action.uuid, action.mediaItemDTOCollection, action.primaryMediaItemIds);
                this._error = null;
                this.emitUpdate();
                break;

            case ActionTypes.REQUEST_GET_COLLECTION_MEDIA_ERROR:
                this._error = action.error;
                this.emitUpdate();
                break;

            // get promotion media
            case ActionTypes.REQUEST_GET_PROMOTION_MEDIA:
                this._createMediaBucket(action.uuid);
                this.emitUpdate();
                break;

            case ActionTypes.REQUEST_GET_PROMOTION_MEDIA_SUCCESS:
                this._fillMediaBucket(action.uuid, action.mediaItemDTOCollection, action.primaryMediaItemIds);
                this._error = null;
                this.emitUpdate();
                break;

            case ActionTypes.REQUEST_GET_PROMOTION_MEDIA_ERROR:
                this._error = action.error;
                this.emitUpdate();
                break;

            // get genre media
            case ActionTypes.REQUEST_GET_GENRE_MEDIA_FILTERED:
                this._createMediaBucket(action.uuid);
                this._genreMediaBucketKey = action.uuid;
                this._genreQueryString = action.query;
                this._genreResultTotalCount = 0;
                this._genreId = action.genreId;
                this._genreClassification = action.classification;
                this._error = null;
                this.emitUpdate();
                break;

            case ActionTypes.REQUEST_GET_GENRE_MEDIA_FILTERED_SUCCESS:
                this._fillMediaBucket(action.uuid, action.mediaItemDTOCollection, action.primaryMediaItemIds, action.resultTotalCount);
                this._genreResultTotalCount = action.resultTotalCount;
                this._error = null;
                this.emitUpdate();
                break;

            case ActionTypes.REQUEST_GET_GENRE_MEDIA_FILTERED_ERROR:
                this._error = action.error;
                this.emitUpdate();
                break;

            // get cast media
            case ActionTypes.REQUEST_GET_CAST_MEDIA:
                this._createMediaBucket(action.uuid);
                this._error = null;
                this.emitUpdate();
                break;

            case ActionTypes.REQUEST_GET_CAST_MEDIA_SUCCESS:
                this._fillMediaBucket(action.uuid, action.mediaItemDTOCollection, action.primaryMediaItemIds);
                this._error = null;
                this.emitUpdate();
                break;

            case ActionTypes.REQUEST_GET_CAST_MEDIA_ERROR:
                this._error = action.error;
                this.emitUpdate();
                break;

            // get watchlist media
            case ActionTypes.REQUEST_GET_WATCHLIST_MEDIA:
                this._createMediaBucket(action.uuid);
                this._error = null;
                this.emitUpdate();
                break;

            case ActionTypes.REQUEST_GET_WATCHLIST_MEDIA_SUCCESS:
                waitFor([WatchlistStore.dispatchToken]); // wait for latest watchlist
                this._updateBucketStorageMediaStates();
                this._fillMediaBucket(action.uuid, action.mediaItemDTOCollection, action.primaryMediaItemIds);
                this._error = null;
                this.emitUpdate();
                break;

            case ActionTypes.REQUEST_GET_WATCHLIST_MEDIA_ERROR:
                this._error = action.error;
                this.emitUpdate();
                break;

            // get recently viewed media
            case ActionTypes.REQUEST_GET_RECENTLY_VIEWED_MEDIA:
                this._createMediaBucket(action.uuid);
                this._error = null;
                this.emitUpdate();
                break;

            case ActionTypes.REQUEST_GET_RECENTLY_VIEWED_MEDIA_SUCCESS:
                waitFor([EntitlementStore.dispatchToken]); // wait for latest entitlements
                this._updateBucketStorageMediaStates();
                this._fillMediaBucket(action.uuid, action.mediaItemDTOCollection, action.primaryMediaItemIds);
                this._error = null;
                this.emitUpdate();
                break;

            case ActionTypes.REQUEST_GET_RECENTLY_VIEWED_MEDIA_ERROR:
                this._error = action.error;
                this.emitUpdate();
                break;

            // get library media
            case ActionTypes.REQUEST_GET_LIBRARY_MEDIA:
                this._createMediaBucket(action.uuid);
                this._error = null;
                this.emitUpdate();
                break;

            case ActionTypes.REQUEST_GET_LIBRARY_MEDIA_SUCCESS:
                waitFor([EntitlementStore.dispatchToken]); // wait for latest entitlements
                this._updateBucketStorageMediaStates();
                this._fillMediaBucket(action.uuid, action.mediaItemDTOCollection, action.primaryMediaItemIds);
                this._error = null;
                this.emitUpdate();
                break;

            case ActionTypes.REQUEST_GET_LIBRARY_MEDIA_ERROR:
                this._error = action.error;
                this.emitUpdate();
                break;

            // get pre-ordered media
            case ActionTypes.REQUEST_GET_WATCHLIST:
                this._createMediaBucket(action.uuid);
                this._error = null;
                this.emitUpdate();
                break;

            case ActionTypes.REQUEST_GET_WATCHLIST_SUCCESS:
                // this._fillMediaBucket(action.uuid, action.mediaItemDTOCollection, action.primaryMediaItemIds);
                this._bucketStorage = this._bucketStorage.set(
                    action.uuid,
                    this._bucketStorage.get(action.uuid)
                        .fill(action.mediaItemDTOCollection, action.primaryMediaItemIds)
                        .setMediaWatchlistStates(action.watchlistItemDTOCollection.reduce((watchlist, watchlistItemDTO) => {
                            let watchlistItem = new WatchlistItem({}).fromDTO(watchlistItemDTO);
                            return watchlistItem.mediaItemId ? watchlist.set(watchlistItem.mediaItemId, watchlistItem) : watchlist;
                        }, Immutable.Map()))
                        .setUpdatePending(false)
                );
                this._error = null;
                this.emitUpdate();
                break;

            // rate media item
            case ActionTypes.REQUEST_RATE_MEDIA_ITEM:
                this._setBucketStorageMediaItemRatingState(action.mediaItemId, true, action.userRating);
                this._error = null;
                this.emitUpdate();
                break;

            case ActionTypes.REQUEST_RATE_MEDIA_ITEM_SUCCESS:
                this._setBucketStorageMediaItemRatingState(action.mediaItemId, false, action.userRating);
                // this._setBucketStorageMediaItemRatingState(action.mediaItemId, false, action.userRating, ratingFromDTO);
                this._error = null;
                this.emitUpdate();
                break;

            case ActionTypes.REQUEST_RATE_MEDIA_ITEM_ERROR:
                this._setBucketStorageMediaItemRatingState(action.mediaItemId, false, null);
                this._error = action.error;
                this.emitUpdate();
                break;

            // sign out user
            case ActionTypes.SIGN_OUT_USER:
                case ActionTypes.REQUEST_SESSION_TIMEOUT:
                waitFor([EntitlementStore.dispatchToken]);
                waitFor([WatchlistStore.dispatchToken]);
                this._updateBucketStorageMediaStates();
                this.emitUpdate();
                break;

            // add/remove media item from watchlist
            case ActionTypes.REQUEST_ADD_MEDIA_ITEM_TO_WATCHLIST:
            case ActionTypes.REQUEST_ADD_MEDIA_ITEM_TO_WATCHLIST_SUCCESS:
            case ActionTypes.REQUEST_ADD_MEDIA_ITEM_TO_WATCHLIST_ERROR:
            case ActionTypes.REQUEST_REMOVE_MEDIA_ITEM_FROM_WATCHLIST:
            case ActionTypes.REQUEST_REMOVE_MEDIA_ITEM_FROM_WATCHLIST_SUCCESS:
            case ActionTypes.REQUEST_REMOVE_MEDIA_ITEM_FROM_WATCHLIST_ERROR:
                waitFor([WatchlistStore.dispatchToken]);
                this._updateBucketStorageMediaItemStates(action.mediaItemId);
                this.emitUpdate();
                break;

            // add media item to MultiBuy basket
            case ActionTypes.REQUEST_ADD_MEDIA_ITEM_OFFER_TO_MULTI_BUY_BASKET:
            case ActionTypes.REQUEST_ADD_MEDIA_ITEM_OFFER_TO_MULTI_BUY_BASKET_SUCCESS:
            case ActionTypes.REQUEST_ADD_MEDIA_ITEM_OFFER_TO_MULTI_BUY_BASKET_ERROR:
                waitFor([EntitlementStore.dispatchToken]);
                this._updateBucketStorageMediaItemStates(action.mediaItemId);
                this.emitUpdate();
                break;

            case ActionTypes.REQUEST_VALIDATE_BUNDLE_OFFER_SUCCESS:
                waitFor([EntitlementStore.dispatchToken]);
                this._updateBucketStorageMediaItemStates(action.mediaItemId);
                var mediaItemDTOCollection = [];
                action.mediaItemDTOCollection.forEach((item) => {
                    mediaItemDTOCollection.push(item);
                });
                this._createMediaBucket(action.uuid);
                this._fillMediaBucket(action.uuid, mediaItemDTOCollection, action.primaryMediaItemIds);
                this._error = null;
                this.emitUpdate();
                break;

            // on fast checkout media item offer
            // on fast checkout bundle offer
            case ActionTypes.REQUEST_FAST_CHECKOUT_MEDIA_ITEM_OFFER:
            case ActionTypes.REQUEST_FAST_CHECKOUT_BUNDLE_OFFER:
                waitFor([PurchaseStore.dispatchToken]);
                this._updateBucketStorageMediaStates();
                this._createMediaBucket(action.uuid);
                this._error = null;
                this.emitUpdate();
                break;

            case ActionTypes.REQUEST_FAST_CHECKOUT_MEDIA_ITEM_OFFER_SUCCESS:
            case ActionTypes.REQUEST_FAST_CHECKOUT_BUNDLE_OFFER_SUCCESS:
                waitFor([PurchaseStore.dispatchToken]);
                this._updateBucketStorageMediaStates();
                this._fillMediaBucket(action.uuid, action.mediaItemDTOCollection, action.primaryMediaItemIds);
                this._error = null;
                this.emitUpdate();
                break;

            case ActionTypes.REQUEST_FAST_CHECKOUT_MEDIA_ITEM_OFFER_ERROR:
            case ActionTypes.REQUEST_FAST_CHECKOUT_BUNDLE_OFFER_ERROR:
                waitFor([PurchaseStore.dispatchToken]);
                this._updateBucketStorageMediaStates();
                this._error = action.error;
                this.emitUpdate();
                break;

            // on charge basket success
            case ActionTypes.REQUEST_CHARGE_BASKET_SUCCESS:
                waitFor([PurchaseStore.dispatchToken]);
                waitFor([EntitlementStore.dispatchToken]);
                waitFor([WatchlistStore.dispatchToken]);
                this._updateBucketStorageMediaStates();
                this.emitUpdate();
                break;

            // on stop media item playback
            case ActionTypes.STOP_MEDIA_ITEM_PLAYBACK:
                waitFor([EntitlementStore.dispatchToken]);
                this._updateBucketStorageMediaStates();
                this.emitUpdate();
                break;

            default:
                break;
        }
    }

    /**
     * Creates media bucket
     *
     * @param {string} bucketKey
     * @private
     */
    _createMediaBucket(bucketKey) {
        if (this._bucketStorage.get(bucketKey)) {
            this._bucketStorage = this._bucketStorage.set(bucketKey, this._bucketStorage.get(bucketKey).setUpdatePending(true));
        } else {
            this._bucketStorage = this._bucketStorage.set(bucketKey, new MediaBucket({
                key: bucketKey,
                updatePending: true
            }));
        }
    }

    /**
     * Set _bucketStorage mediaBucket
     *
     * @param {string} bucketKey
     * @param {Array<MediaItemDTO>} mediaItemDTOList
     * @param {Array<string>} primaryMediaItemIds
     * @param {?number} mediaTotalCount
     * @private
     */
    _fillMediaBucket(bucketKey, mediaItemDTOList, primaryMediaItemIds, mediaTotalCount = null) {
        let mediaBucket = this._bucketStorage.get(bucketKey);
        if (!mediaBucket) return;

        mediaBucket = mediaBucket.setMediaTotalCount(mediaTotalCount);
        mediaBucket = mediaBucket.fill(mediaItemDTOList, primaryMediaItemIds);
        mediaBucket = mediaBucket.setMediaEntitlementStates(EntitlementStore.currentEntitlementsByMediaItem);
        mediaBucket = mediaBucket.setMediaWatchlistStates(WatchlistStore.watchlist);
        mediaBucket = mediaBucket.setMediaRecentlyViewedStates(EntitlementStore.recentlyViewedItems);
        mediaBucket = mediaBucket.setMediaOffersBasketStates(PurchaseStore.basket);
        mediaBucket = mediaBucket.setUpdatePending(false);

        this._bucketStorage = this._bucketStorage.set(mediaBucket.key, mediaBucket);
    }

    /**
     * Update bucket storage media states
     *
     * @private
     */
    _updateBucketStorageMediaStates() {
        /** @type {MediaBucket} mediaBucket */
        this._bucketStorage.forEach((mediaBucket, key) => {
            mediaBucket = mediaBucket.setMediaEntitlementStates(EntitlementStore.currentEntitlementsByMediaItem);
            mediaBucket = mediaBucket.setMediaWatchlistStates(WatchlistStore.watchlist);
            mediaBucket = mediaBucket.setMediaRecentlyViewedStates(EntitlementStore.recentlyViewedItems);
            mediaBucket = mediaBucket.setMediaOffersBasketStates(PurchaseStore.basket);
            this._bucketStorage = this._bucketStorage.set(key, mediaBucket);
        });
    }

    /**
     * Update bucket storage mediaItem states
     *
     * @param mediaItemId
     * @private
     */
    _updateBucketStorageMediaItemStates(mediaItemId) {
        /** @type {MediaBucket} mediaBucket */
        this._bucketStorage.forEach((mediaBucket, key) => {
            if (mediaBucket.mediaItem(mediaItemId)) {
                let mediaItem = mediaBucket.mediaItem(mediaItemId);
                mediaBucket = mediaBucket.setMediaItemEntitlementState(mediaItem, EntitlementStore.currentEntitlementsByMediaItem);
                mediaBucket = mediaBucket.setMediaItemWatchlistState(mediaItem, WatchlistStore.watchlist);
                if (mediaItem.classification === MediaItemClassification.SEASON) {
                    mediaBucket = mediaBucket.setMediaWatchlistStates(WatchlistStore.watchlist);
                }
                mediaBucket = mediaBucket.setMediaItemRecentlyViewedState(mediaItem, EntitlementStore.recentlyViewedItems);
                mediaBucket = mediaBucket.setMediaItemOffersBasketState(mediaItem, PurchaseStore.basket);
                this._bucketStorage = this._bucketStorage.set(key, mediaBucket);
            }
        });
    }

    /**
     * Set bucket storage mediaItem rating pending state
     *
     * @param {string} mediaItemId
     * @param {boolean} ratingPending
     * @param {?number} userRating
     * @param {?number} rating
     * @private
     */
    _setBucketStorageMediaItemRatingState(mediaItemId, ratingPending, userRating = null, rating = null) {
        /** @type {MediaBucket} mediaBucket */
        this._bucketStorage.forEach((mediaBucket, key) => {
            if (mediaBucket.mediaItem(mediaItemId)) {
                let mediaItem = mediaBucket.mediaItem(mediaItemId);
                mediaBucket = mediaBucket.setMediaItemRatingState(mediaItem, ratingPending, userRating, rating);
                this._bucketStorage = this._bucketStorage.set(key, mediaBucket);
            }
        });
    }

    /**
     * Register media state change
     * - writes last change time into localStorage
     */
    registerMediaStateChange() {
        store.set("media_state_change", moment().toJSON());
    }

    /**
     * Get mediaBucket from storage
     *
     * returns {?MediaBucket}
     */
    getMediaBucket(bucketKey) {
        return this._bucketStorage.get(bucketKey);
    }

    /**
     * Destroy media bucket (anti-pattern: called directly on store)
     */
    destroyMediaBucket(bucketKey) {
        this._bucketStorage = this._bucketStorage.delete(bucketKey);
        return null;
    }

    /**
     * Get _searchQueryString
     *
     * @returns {string}
     */
    get searchQueryString() {
        return this._searchQueryString;
    }

    /**
     * Get _searchMediaBucketKey
     *
     * @returns {string}
     */
    get searchMediaBucketKey() {
        return this._searchMediaBucketKey;
    }

    /**
     * Get _searchResultTotalCount
     *
     * @returns {number}
     */
    get searchResultTotalCount() {
        return this._searchResultTotalCount;
    }

    /**
     * Get _genreMediaBucketKey
     *
     * @returns {string}
     */
    get genreMediaBucketKey() {
        return this._genreMediaBucketKey;
    }


    /**
     * Get _genreId
     *
     * @returns {string}
     */
    get genreId() {
        return this._genreId;
    }

    /**
     * Get _genreClassification
     *
     * @returns {string}
     */
    get genreClassification() {
        return this._genreClassification;
    }

    /**
     * Get _genreResultTotalCount
     *
     * @returns {number}
     */
    get genreResultTotalCount() {
        return this._genreResultTotalCount;
    }

    /**
     * Get _error
     *
     * @returns {null|*}
     */
    get error() {
        return this._error;
    }
}

export default new MediaStore();
