"use strict";

import Immutable from 'immutable';
import {waitFor} from '../dispatchers/AppDispatcher';
import ActionTypes from '../actions/ActionTypes';
import Alert, {AlertStatus} from '../types/Alert';
import WatchlistItem from '../types/WatchlistItem';
import BaseStore from './BaseStore';
import PurchaseStore from './PurchaseStore';
import EntitlementStore from './EntitlementStore';
import MediaStore from './MediaStore';

/**
 * Flux: WatchlistStore
 */
class WatchlistStore extends BaseStore {
    /**
     * Watchlist
     *
     * type {Immutable.Map<string, WatchlistItem>} _watchlist
     * @private
     */
    _watchlist = Immutable.Map();

    /**
     * Pre-order alerts
     *
     * type {Immutable.OrderedMap<string, Alert>} _preOrderedMediaItemAlerts
     * @private
     */
    _preOrderedMediaItemAlerts = Immutable.OrderedMap();

    /**
     * @type {string} _preOrderedMediaBucketKey
     * @private
     */
    _preOrderedMediaBucketKey = '';

    /**
     * Constructor
     */
    constructor() {
        super();
        this.subscribe(() => this._registerToActions.bind(this));
    }

    /**
     * Flux: register store to actions
     *
     * @param action
     * @private
     */
    _registerToActions(action) {
        switch (action.type) {
            // get watchlist
            case ActionTypes.REQUEST_GET_WATCHLIST:
                waitFor([MediaStore.dispatchToken]);
                this._preOrderedMediaBucketKey = action.uuid;
                this._error = null;
                break;

            case ActionTypes.REQUEST_GET_WATCHLIST_SUCCESS:
                waitFor([MediaStore.dispatchToken]);
                this._setWatchlist(action.watchlistItemDTOCollection);
                this._setPreOrderedMediaItemAlerts(action.alertDTOCollection);
                this._error = null;
                this.emitUpdate();
                break;

            case ActionTypes.REQUEST_GET_WATCHLIST_ERROR:
                this._error = action.error;
                this.emitUpdate();
                break;

            // on get watchlist media
            case ActionTypes.REQUEST_GET_WATCHLIST_MEDIA_SUCCESS:
                this._setWatchlist(action.watchlistItemDTOCollection);
                this._error = null;
                this.emitUpdate();
                break;

            // add media item to watchlist
            case ActionTypes.REQUEST_ADD_MEDIA_ITEM_TO_WATCHLIST:
                this._watchlist = this._watchlist.set(action.mediaItemId, new WatchlistItem({
                    offerId: action.offerId,
                    mediaItemId: action.mediaItemId,
                    isOnWatchlist: false,
                    updatePending: true
                }));
                this._error = null;
                this.emitUpdate();
                break;

            case ActionTypes.REQUEST_ADD_MEDIA_ITEM_TO_WATCHLIST_SUCCESS:
                this._watchlist = this._watchlist.set(action.mediaItemId, new WatchlistItem({}).fromDTO(action.watchlistItemDTO));
                MediaStore.registerMediaStateChange();
                this._error = null;
                this.emitUpdate();
                break;

            case ActionTypes.REQUEST_ADD_MEDIA_ITEM_TO_WATCHLIST_ERROR:
                this._watchlist = this._watchlist.delete(action.mediaItemId);
                this._error = action.error;
                this.emitUpdate();
                break;

            // remove media item from watchlist
            case ActionTypes.REQUEST_REMOVE_MEDIA_ITEM_FROM_WATCHLIST:
                this._watchlist = this._watchlist.set(action.mediaItemId, this._watchlist.get(action.mediaItemId).setUpdatePending(true));
                this._error = null;
                this.emitUpdate();
                break;

            case ActionTypes.REQUEST_REMOVE_MEDIA_ITEM_FROM_WATCHLIST_SUCCESS:
                this._watchlist = this._watchlist.delete(action.mediaItemId);
                this._preOrderedMediaItemAlerts = this._preOrderedMediaItemAlerts.delete(action.mediaItemId);
                MediaStore.registerMediaStateChange();
                this._error = null;
                this.emitUpdate();
                break;

            case ActionTypes.REQUEST_REMOVE_MEDIA_ITEM_FROM_WATCHLIST_ERROR:
                this._watchlist = this._watchlist.set(action.mediaItemId, this._watchlist.get(action.mediaItemId).setUpdatePending(false));
                this._error = action.error;
                this.emitUpdate();
                break;

            // close pre-ordered mediaItem alert
            case ActionTypes.CLOSE_PRE_ORDERED_MEDIA_ITEM_ALERT:
                this._preOrderedMediaItemAlerts = this._preOrderedMediaItemAlerts.delete(action.mediaItemId);
                this._error = null;
                this.emitUpdate();
                break;

            // cancel pre-ordered mediaItem alert
            case ActionTypes.REQUEST_CANCEL_PRE_ORDERED_MEDIA_ITEM_ALERT:
                this._preOrderedMediaItemAlerts = this._preOrderedMediaItemAlerts.set(action.mediaItemId, this._preOrderedMediaItemAlerts.get(action.mediaItemId).setUpdatePending(true));
                this._error = null;
                this.emitUpdate();
                break;

            case ActionTypes.REQUEST_CANCEL_PRE_ORDERED_MEDIA_ITEM_ALERT_SUCCESS:
                this._preOrderedMediaItemAlerts = this._preOrderedMediaItemAlerts.delete(action.mediaItemId);
                this._error = null;
                this.emitUpdate();
                break;

            case ActionTypes.REQUEST_CANCEL_PRE_ORDERED_MEDIA_ITEM_ALERT_ERROR:
                this._preOrderedMediaItemAlerts = this._preOrderedMediaItemAlerts.set(action.mediaItemId, this._preOrderedMediaItemAlerts.get(action.mediaItemId).setUpdatePending(false));
                this._error = action.error;
                this.emitUpdate();
                break;

            // on charge basket success
            case ActionTypes.REQUEST_CHARGE_BASKET_SUCCESS:
                waitFor([PurchaseStore.dispatchToken]);
                waitFor([EntitlementStore.dispatchToken]);
                this._setWatchlist(action.watchlistItemDTOCollection);
                this._error = null;
                this.emitUpdate();
                break;

            // on sign out clear watchlist
            case ActionTypes.SIGN_OUT_USER:
                case ActionTypes.REQUEST_SESSION_TIMEOUT:
                this._watchlist = Immutable.Map();
                MediaStore.destroyMediaBucket(this._preOrderedMediaBucketKey);
                this._preOrderedMediaItemAlerts = Immutable.OrderedMap();
                this._preOrderedMediaBucketKey = '';
                break;

            default:
                break;
        }
    }

    /** Delete items from _watchlist **/
    _deleteItemsFromWatchList(list) {
        if (typeof this._watchlist.deleteAll === 'function') {
            this._watchlist = this._watchlist.deleteAll(list);
        } else {
            this._watchlist = list.reduce((list, item) => {
                return list.delete(item);
            }, this._watchlist);
        }
    }

    /** Delete items from _preOrderedMediaItemAlerts **/
    _deleteItemsFromPreOrderedMediaItemAlerts(list) {
        if (typeof this._preOrderedMediaItemAlerts.deleteAll === 'function') {
            this._preOrderedMediaItemAlerts = this._preOrderedMediaItemAlerts.deleteAll(list);
        } else {
            this._preOrderedMediaItemAlerts = list.reduce((list, item) => {
                return list.delete(item);
            }, this._preOrderedMediaItemAlerts);
        }
    }

    /**
     * Set _watchlist
     *
     * @param {Array<WatchlistItemDTO>} watchlistItemDTOCollection
     * @private
     */
    _setWatchlist(watchlistItemDTOCollection) {
        this._watchlist = Immutable.Map();

        watchlistItemDTOCollection.forEach((watchlistItemDTO) => {
            let watchlistItem = new WatchlistItem({}).fromDTO(watchlistItemDTO);
            if (watchlistItem.mediaItemId) {
                this._watchlist = this._watchlist.set(watchlistItem.mediaItemId, watchlistItem);
            }
        });
    }

    /**
     * Set _preOrderedMediaItemAlerts
     *
     * @param {Array<AlertDTO>} alertDTOCollection
     * @private
     */
    _setPreOrderedMediaItemAlerts(alertDTOCollection) {
        this._preOrderedMediaItemAlerts = Immutable.OrderedMap();

        alertDTOCollection.forEach((alertDTO) => {
            let alert = new Alert({}).fromDTO(alertDTO);
            if (alert.referenceId && alert.status === AlertStatus.ACTIVE) {
                this._preOrderedMediaItemAlerts = this._preOrderedMediaItemAlerts.set(alert.referenceId, alert);
            }
        });
    }

    /**
     * Get _watchlist
     *
     * @returns {Immutable.Map<string, WatchlistItem>}
     */
    get watchlist() {
        return this._watchlist;
    }

    /**
     * Get _preOrderedMediaItemAlerts
     *
     * @returns {Immutable.OrderedMap<string, Alert>}
     */
    get preOrderedMediaItemAlerts() {
        return this._preOrderedMediaItemAlerts;
    }

    /**
     * Get _preOrderedMediaBucketKey
     *
     * @returns {string}
     */
    get preOrderedMediaBucketKey() {
        return this._preOrderedMediaBucketKey;
    }

    /**
     * Get _error
     *
     * @returns {null|*}
     */
    get error() {
        return this._error;
    }
}

export default new WatchlistStore();
